import React, { PureComponent } from "react";
import "../../../config/import";
import logoLogin from "../../../assets/images/logo.jpg";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import "../../../assets/styles/login.css";

class Signup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: "signup",
      signup_first_name: "",
      last_name: "",
      signup_email: "",
      signup_password: "",
      signup_error_message: "",
      reset_msg: ""
    };
    this.checkInput = this.checkInput.bind(this);
    document.body.classList.add('hide-leftnav');
  }

  componentDidMount() {
    if (localStorage.getItem('User')) {
      this.props.router.push("/documents");
    }
    this.setState({
      signup_error_message: "",
      reset_msg: ""
    });
  }

  checkInput(e) {
    let target = e.currentTarget
      if (target.id === "regFname" && target.validity.valueMissing) {
        target.setCustomValidity("Enter your name");
        document.querySelector("#nameError").innerHTML = target.validationMessage;
      }
      else if (target.id === "regFname" && !target.validity.valueMissing) {
        target.setCustomValidity("");
        document.querySelector("#nameError").innerHTML = target.validationMessage;
      }
      else if (target.id === "regEmailId" && target.validity.valueMissing) {
        target.setCustomValidity("Enter email address");
        document.querySelector("#signEmailError").innerHTML = target.validationMessage;
      }
      else if(target.id === "regEmailId" && !document.getElementById('regEmailId').value.includes('@') || !document.getElementById('regEmailId').value.includes('.') || e.currentTarget.value.split('@')[0].trim() == '' ){
        target.setCustomValidity("Please enter valid email address ");
        document.querySelector("#signEmailError").innerHTML = target.validationMessage;
      }
      else if (target.id === "regEmailId" && !target.validity.valueMissing) {
        target.setCustomValidity("");
        document.querySelector("#signEmailError").innerHTML = target.validationMessage;
      }
      else if (target.id === "regpassword" && target.validity.valueMissing) {
        target.setCustomValidity("Enter password");
        document.querySelector("#signPassError").innerHTML = target.validationMessage;
      }
      else if (target.id === "regpassword" && target.value.length < 8 ) {
        target.setCustomValidity("Password length must be at least 8 characters ");
        document.querySelector("#signPassError").innerHTML = target.validationMessage;
        return false;
      }
      else if (target.id === "regpassword" && !target.validity.valueMissing) {
        target.setCustomValidity("");
        document.querySelector("#signPassError").innerHTML = target.validationMessage;
      }
  }

  validateCredentials() {
    if (this.state.mode === "signup") {
      let name = document.getElementById('regFname');
      let signup_email = document.getElementById('regEmailId');
      let signup_pass = document.getElementById('regpassword');
      let policy = document.getElementById('policyCheck');
      if (name.validity.valueMissing) {
        name.setCustomValidity("Enter your name");
      }
      else {
        name.setCustomValidity("");
      }
      if (signup_email.validity.valueMissing) {
        signup_email.setCustomValidity("Enter email address");
      }
      else {
        signup_email.setCustomValidity("");
      }
      if(!document.getElementById('regEmailId').value.includes('@') || !document.getElementById('regEmailId').value.includes('.') || document.getElementById('regEmailId').value.split('@')[0].trim() == '' ){
        signup_email.setCustomValidity("Please enter valid email address ");
      }
      else{
        signup_email.setCustomValidity("");
      }
      if (signup_pass.validity.valueMissing) {
        signup_pass.setCustomValidity("Enter password");
      }
      else {
        signup_pass.setCustomValidity("");
      }
      if (signup_pass.value.length < 8 ) {
        signup_pass.setCustomValidity("Password length must be at least 8 characters ");
        return false;
      }
      else{
        signup_pass.setCustomValidity("");
      }

      if (!policy.checked) {
        policy.setCustomValidity("You must select privacy policy to register");
      }
      else {
        policy.setCustomValidity("");
      }
      document.querySelector("#nameError").innerHTML = name.validationMessage;
      document.querySelector("#signEmailError").innerHTML = signup_email.validationMessage;
      document.querySelector("#signPassError").innerHTML = signup_pass.validationMessage;
      document.querySelector("#policyError").innerHTML = policy.validationMessage;
    }
  }

  validateInput = function(e) {
    let form = document.getElementsByTagName('form')[0];
    this.validateCredentials();
    if (form.checkValidity() === false) {
      return false;
    } else {
      return true;
    }
  }

  register = function(e){
      if(!this.validateInput(e)) {
      e.preventDefault();
      return false;
    }
    const paramData = this.state;
    localStorage.setItem('emailAddress','');
    localStorage.setItem('emailAddress',paramData.signup_email);
    const apiData = {
      first_name: paramData.signup_first_name,
      email: paramData.signup_email,
      password: paramData.signup_password
    }

    apiHandler
      .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.signup), {}, apiData)
      .then(response => {
        if(response.status != '201') {
          if(response.data.message == 'User with this account already exists'){
            document.querySelector("#signEmailError").innerHTML = response.data.message;
            return false;
          }
        }
        else {
          this.props.router.push({pathname: '/login', state:{response_msg: response.data.message, mode:"confirmation"}});
        }
      })
      .catch(err => {
        console.log("err", err);
      });
    e.preventDefault()
  }
  render() {
    let formComp;
    let alert;
    if (this.props.location.state) {
      this.setState({reset_msg: this.props.location.state.message})
      alert = <span className="alert-success">{this.props.location.state.message}</span>
    }
    if (this.state.signup_error_message) {
      alert = <span className='alert-danger'>{this.state.login_error_message}</span>
    }

    formComp = <div className="createaccountwrapper">
          <h2 className="logtxt">Create an account</h2>
          <span className='alert-danger'>{this.state.signup_error_message}</span>
          <p className="lohptxt resgloginlohptxt"></p>
          <form>
            <div className="form-group">
                <input onKeyDown={this.restrict} type="text" className="form-control" placeholder="First name" id="regFname" onBlur={e => this.checkInput(e)}
                value={this.state.signup_first_name} required={true}
                onChange={e => {
                  let value = e.target.value;
                  value = value.replace(/[^A-Za-z]/gi, "");
                  this.setState({
                    signup_first_name: value
                  });
                }}/>
                <div className="invalid" id="nameError"></div>
            </div>
            <div className="form-group">
                <input type="text" className="form-control" placeholder="Email Address" id="regEmailId" onBlur={e => this.checkInput(e)}
                value={this.state.signup_email} required={true}
                onChange={e => {
                  this.setState({ signup_email: e.target.value })
                }}/>
                <div className="invalid" id="signEmailError"></div>
            </div>
            <div className="form-group custom_margin">
                <input type="password" className="form-control" placeholder="Password" id="regpassword" onBlur={e => this.checkInput(e)}
                value={this.state.signup_password} required={true}
                onChange={e => {
                  this.setState({ signup_password: e.target.value })
                }}/>
                <div className="invalid" id="signPassError"></div>
            </div>
            <div className="form-group">
                <span className="remme"><input type="checkbox" id="policyCheck" required={true}/>I agree to the <a href="/trust/privacy/privacy-policy" target="_blank">Privacy
                        Policy</a></span>
                <div className="invalid custom_errorpolicy" id="policyError"></div>

            </div>
            <div className="form-group text-center">
                <button type="button" className="logBtn" id="resgBtn"
                onClick={e => {
                  this.register(e);
                }} >REGISTER</button>
            </div>
          </form>
          <div className="regtxt">Already have an account? <a href="/login" className="clicklink">Log In</a></div>
      </div>

    return (
      <React.Fragment>
        <div className="mainloginWrapper">
          <div className="logo"><img src={logoLogin} className="img-responsive" /></div>
          <div className="innerwrapper">
            {formComp}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Signup;

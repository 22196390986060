var timeout =[]; 

export function resetSession() {
  var now = new Date();
  var time = now.getTime();
  var expireTime = time + 1000*5*60;
  now.setTime(expireTime);
  localStorage.setItem('patakapdf-session',expireTime);
  resetPopup();
}

function sessionPopup() {
  var sec = 60;
  document.getElementById('sessionExpire').style.display = 'block';
  
  function countdown() {
     document.getElementById('counter').innerHTML = sec;
     sec--;
     if (sec < 0) {
        localStorage.removeItem('User');
        localStorage.removeItem('customerApiKey');
        localStorage.removeItem('patakapdf-session');
        document.getElementById('sessionBody').innerHTML = "Your session has been expired";
        document.getElementById('sessionContinue').classList.add('hide');
        document.getElementById('sessionExit').classList.add('hide');
        document.getElementById('sessionLogin').classList.remove('hide');
     }
     else {
        timeout.push(setTimeout(countdown, 1000));
     }
  }
  countdown();
}

function resetPopup() {
    timeout.map((item) => {
        clearTimeout(item);
    });

    var timer = parseInt(localStorage.getItem('patakapdf-session'));
    timeout.push(setTimeout(sessionPopup, timer - ((timer-1000*5*60)+1000*1*60)));
    if (document.getElementById('sessionExpire') != null) {
        document.getElementById('sessionExpire').style.display = 'none';
    }
}

function sessionExpired() {
  document.getElementById('sessionExpire').style.display = 'block';
  document.getElementById('sessionBody').innerHTML = "Your session has been expired";
  document.getElementById('sessionContinue').classList.add('hide');
  document.getElementById('sessionExit').classList.add('hide');
  document.getElementById('sessionLogin').classList.remove('hide');
}

function isUserLoggedIn() {
  if (localStorage.getItem('customerApiKey') != undefined) {
    return true;
  }
  else {
    return false;
  }
}

export function logout() {
  localStorage.clear();
  window.location.href = "/";
}

export function redirectToLogin() {
  localStorage.clear();
  window.location.href = "/login";
}

window.addEventListener('load', ()=> {
  if (isUserLoggedIn()) {
    resetSession();
  }
  else if (!isUserLoggedIn() && localStorage.getItem('patakapdf-session') != undefined) {
    sessionExpired();
  }
});

document.addEventListener('mouseover',()=>{
    if (document.getElementById('sessionExpire').style.display == 'none' && isUserLoggedIn()) {
       resetSession();
    }
 });

window.addEventListener('storage', function() {
  if (localStorage.getItem('patakapdf-session') != undefined && isUserLoggedIn()) {
    resetSession();
  }
  else if (localStorage.getItem('patakapdf-session') == undefined) {
    window.location.href = "/"
  }
}, true);

import React, { Component } from 'react';
import '../../../config/config';
import '../../../assets/styles/document.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import { faFilePdf, faFolder, faEllipsisV, faPlusCircle, faFile, faTrash, faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Label, Input, UncontrolledCollapse, Button, CardBody, Card, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import loaderGif from '../../../assets/images/loader.gif'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
var timeout = [];
export class Document extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loggedInUser: JSON.parse(localStorage.getItem('User')),
         directoryData: '',
         currentDirectoryData: '',
         folderValue: '',
         parentSelected: '',
         newFolderName: '',
         upCrHeading: '',
         currentUploadPdf: '',
         loading: false,
         isDelOpen: false,
         isResOpen: false,
         uid:"",
         deletedDocs: "",
         deletedDirectories: "",
         resMessage: "",
         mode: "folders",
         currentFolder: "",
         isRoot: true,
         bradcunmenu : 'Root',
         breadCrumArr: [],
         newArr: [],
         uploadOption: "",
         uploadFile: {},
         newFileName: "",
         delMode: "document",
         resMode: "document",
         uploadOrCreateResMsg: '',
         searchResult: [],
         fileSearch: '',
         uploadImage: '',
         searchMode: 'trash'
      };
      this.createFolderOrUploadFile = this.createFolderOrUploadFile.bind(this);
      this.enterFunction = this.enterFunction.bind(this);
      this.validateFileName = this.validateFileName.bind(this);
      this.searchDocument = this.searchDocument.bind(this);
      this.convertToBase64 = this.convertToBase64.bind(this);
      this.handleDropDown = this.handleDropDown.bind(this);
   }

   getAuthToken = function() {
      const currentState = this.state;
      let authToken;
      if(currentState.loggedInUser === null) {
         this.props.router.push('/');
         return false;
      } else{
         authToken = currentState.loggedInUser['authToken'];
      }
      return authToken;
   }

   showTabs = function (e) {
      let previousActiveLink = document.getElementsByClassName('activelist');
      let previousActiveDisplay = document.getElementById((previousActiveLink[0].getAttribute('data-conid')));
      previousActiveDisplay.setAttribute('style', 'display: none;');
      var folderDisplay = document.getElementById('FolderViewContainer');
      folderDisplay.setAttribute('style', 'display: none;');
      previousActiveLink[0].classList.remove('activelist');
      let currentActiveLink = e.currentTarget;
      const dataConid = currentActiveLink.getAttribute('data-conid')
      let currentActiveDisplay = document.getElementById(dataConid);
      document.getElementById('bradecum').style.display = 'none';
      if (dataConid == 'FolderContainer') {
         this.getUserDirectories();

      }
      currentActiveLink.className += 'activelist'
      currentActiveDisplay.setAttribute('style', '');
      let  { newArr } = this.state
      newArr = [];  
      this.setState({
         newArr
      })
   }

   selectDeselectAll = function(e) {
      let setter = e.currentTarget.checked;
      let fileFolderCheckboxes = document.getElementsByClassName('folderAndFileCheck');
      for(let i = 0; i < fileFolderCheckboxes.length; i++){
         fileFolderCheckboxes[i].firstElementChild.checked = setter;
      }

   }

   deSelectAll = function(e){
      let setter = e.currentTarget.checked;
      let allSelect = document.getElementsByClassName('allSelectFolder');
      let fileFolderCheckboxes = document.getElementsByClassName('folderAndFileCheck');
      let lengthOFChildren = fileFolderCheckboxes.length;
      let count  = 0;
      if(setter === false){
         allSelect[0].checked = setter
      }
      for(let i = 0; i < lengthOFChildren; i++){
         if(fileFolderCheckboxes[i].firstElementChild.checked ){
            count++;
         }
      }


      if(count ==lengthOFChildren) {
         allSelect[0].checked = true
      }

   }

   showActionItems = function (e) {
      var actionMenu = e.currentTarget.children[1];
      actionMenu.classList.remove('atitems-hide');
   }
   handleOptionBlur = function(e){
      var actionMenu = e.currentTarget;
      actionMenu.classList.add('atitems-hide');
      return true;
   }
   getUserDirectories = function () {

      const authToken = this.getAuthToken();
      if(authToken) {
         apiHandler
            .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.getDirectories), { Authorization: authToken })
            .then(response => {
               if (response.status === 200) {
                  const apiResponse = response;
                  if (apiResponse.data.code = 'success') {
                     this.setState({ directoryData: apiResponse.data.data.directory, currentFolder:  apiResponse.data.data.directory.uid});
                     apiHandler
                        .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.customerFetch), { Authorization: authToken })
                        .then(fetchResponse => {
                        const customerApiKey = fetchResponse.data.data.customer.customer_api_key
                        localStorage.setItem('customerApiKey', customerApiKey);
                        })
                  }
               } else {
                  this.props.router.push('/subscription')
               }
            })
            .catch(err => {
               console.log("err", err);
               alert('Something went wrong....');
               return false;
            });
      }

   }

   fetchFolder = function (directory_uid) {
      const currentState = this.state;
      const authToken = currentState.loggedInUser['authToken'];
      apiHandler
         .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.getDirectories + '?directory_uid=' + directory_uid), { Authorization: authToken })
         .then(resp => {
            if (resp.data && resp.data.data.directory && resp.data.data.directory.directories) {
               this.setState({
                  breadCrumArr: resp.data.data.directory.directories,
                  directoryData: resp.data.data.directory,
                  currentFolder: directory_uid
               })
               document.getElementById('totalDocument').innerHTML = this.state.directoryData.documents.length;
               if (this.state.newArr.length != 0) {
                  document.getElementById('bradecum').style.display = 'block';
               }
               var folderContainer = document.getElementById('FolderContainer')
               var folderDisplay = document.getElementById('FolderViewContainer');
            }else{
                 alert('no directories or files');
             }
         })
         .catch(err => {
            console.log("err", err);
            alert('Something went wrong....');
            return false;
         });
   }

   openPdffile = function (document_uid, event) {
      const currentState = this.state;
      const authToken = currentState.loggedInUser['authToken'];
      const docObject = { fileName: event.currentTarget.innerText,
                          docId: document_uid};
      var windowLocation = window.open("about:blank","_blank");
      this.pdfDetailsToLocal(docObject);
      apiHandler
         .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.pdfFile + '/' + document_uid + '/url'), { Authorization: authToken })
         .then(response => {
            if (response.status === 200) {
               const apiResponse = response.data;
               if (apiResponse.code = 'success') {
                  windowLocation.location = apiResponse.data.document_url;
                  windowLocation.focus();
               }
            } else {
               alert('no such document');
            }
         })
         .catch(err => {
            console.log("err", err);
            alert('Something went wrong....');
            return false;
         });
   }

   pdfDetailsToLocal = function(obj) {
     let versionObj = localStorage.getItem('pdfVersionDetails');
     if(versionObj) {
       let tempVersion = JSON.parse(versionObj);
       tempVersion[obj["fileName"]] = {
         id: '1',
         method: 'Versioning',
         docUID: obj["docId"],
         fileName: obj["fileName"],
         customerApiKey: localStorage.getItem('customerApiKey')
       }
       localStorage.setItem('pdfVersionDetails', JSON.stringify(tempVersion));
     } else {
       versionObj = {}
       versionObj[obj["fileName"]] = {
         id: '1',
         method: 'Versioning',
         docUID: obj["docId"],
         fileName: obj["fileName"],
         customerApiKey: localStorage.getItem('customerApiKey')
       }
       localStorage.setItem('pdfVersionDetails', JSON.stringify(versionObj));
     }
   }

   enterFunction(e,mode) {
      if (e.keyCode === 13 && mode === "Create Folder") {
         e.preventDefault();
         this.createFolderOrUploadFile(e, "Create Folder");
      }
      else if (e.keyCode === 13 && document.getElementById('searchInput').value != "") {
         this.searchDocument();
      }
   }

   componentDidMount() {
      this.getUserDirectories();
      document.addEventListener("keydown", (e) => {this.enterFunction(e,this.state.upCrHeading)}, false);
   }
   triggerPopup = function (e) {
      this.setState({ upCrHeading: e, parentSelected: this.state.currentFolder})
      document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
      let popUp = document.getElementById('createFolder');
      popUp.removeAttribute('style');
      let items = [];
      
      if(this.state.directoryData !== undefined){
         if(this.state.directoryData.directories !== undefined){
            for (let i = 0; i < this.state.directoryData.directories.length; i++) {
               items.push(<option key={i} value={this.state.directoryData.directories[i].uid}>{this.state.directoryData.directories[i].name}</option>);
            }
         }
         items.push(<option key='0' value={this.state.directoryData.uid} selected="selected">{this.state.directoryData.name}</option>);
         this.setState({ folderValue: items})
      }
      if (this.state.upCrHeading != "Create Folder") {
        let newFile = document.getElementById('uploadPdfFile');
        this.setState({parentSelected: this.state.directoryData.uid});
        if(newFile.value !== null){
           newFile.value = '';
        }
      }
   }

   createFolderOrUploadFile = function (e, callType) {
      
      const currentState = this.state;
      const authToken = currentState.loggedInUser['authToken'];
      let endPoint = '';
      let apiHeader = '';
      let data = {};
      let form_data = new FormData();
      if (callType === 'Create Folder') {
         data = {
            name: this.state.newFolderName,
            parent_directory_uid: this.state.parentSelected,
         }
         apiHeader = {
            Authorization: authToken
         }
         endPoint = apiUrls.endPoints.createDirectories
      } else {
         let pdfFileObj = document.getElementById('uploadPdfFile').files;
         if (pdfFileObj != undefined) {
           let parentFolder = document.getElementById('parentFolder')
            for(let i=0;i<pdfFileObj.length;i++) {
               form_data.append("file[]", pdfFileObj[i], pdfFileObj[0].name);
            }
           form_data.append('directory_uid', this.state.parentSelected !== '' ? this.state.parentSelected : parentFolder.options[parentFolder.selectedIndex].value);
           if (['.png','.jpg','.jpeg'].some(substring=>pdfFileObj[0].name.includes(substring))) {
              form_data.append("type","image");
           }
           if (parentFolder.options[parentFolder.selectedIndex].innerHTML != 'ROOT') {
             this.setState({isRoot: false});
           }
         }
         this.setState({uploadFile: pdfFileObj, loading: true});
         apiHeader = {
            Authorization: authToken,
            'Content-Type': 'multipart/form-data'
         }
         endPoint = this.state.uploadOption === "Replace" ? apiUrls.endPoints.replacePdf : apiUrls.endPoints.pdfFile
      }
      apiHandler
         .postRequest((apiUrls.baseUrl.development + endPoint), apiHeader, Object.keys(data).length ==  0 ? form_data : data)
         .then(response => {
            if (response.status === 201) {
               const apiResponse = response.data;
               if (apiResponse.code = 'success') {
                  if(callType === 'Create Folder') {
                     this.fetchFolder(this.state.currentFolder);
                     this.closePopup();
                     return false;
                  }
                  const currentState = this.state;
                  const authToken = currentState.loggedInUser['authToken'];
                  const status_uid = apiResponse.data.status_uid;
                  const selectedFolder = document.querySelector('#parentFolder');
                  this.setState({loading: true, uploadOption: ""});
                  this.fileUploadStatus(status_uid, authToken);
                  if (this.state.currentFolder != this.state.parentSelected) {
                     this.fetchFolderArr(null,selectedFolder[selectedFolder.selectedIndex].text,this.state.parentSelected);
                  }
               }
            } else if (response.status === 409 || response.status === 400) {
               this.setState({uploadOrCreateResMsg: response.data.message.replace("Directory not created", "Folder name already exists in other folder"), loading: false})
            } else {
               this.setState({loading: false});
            }
         })
         .catch(err => {
            console.log("err", err);
            alert('Something went wrong....');
            return false;
         });

   }

   closePopup = function (e) {
      let popUp;
      popUp = document.getElementById('createFolder');
      if (this.state.upCrHeading == "Create Folder") {
        document.getElementById("folderName").value = "";
      }
      popUp.setAttribute('style', 'display: none');
      document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
      document.getElementsByClassName('movebtn')[0].classList.add('disabled');
      if (this.state.upCrHeading == 'Upload Document') {
        var uploadFile = document.querySelector('#uploadPdfFile');
        uploadFile.setCustomValidity("");
        document.querySelector("#uploadError").innerHTML = "";
      }
      this.setState({uploadOrCreateResMsg:""});
      document.querySelector(".msgWrap").innerHTML = "";
   }

   deleteDocument = function(uid) {
      let authToken = this.getAuthToken();
      apiHandler.postRequest((apiUrls.baseUrl.development + '/documents/' + this.state.uid + '/delete'),{ Authorization: authToken })
      .then(response => {
         if (response.status === 200) {
            this.setState({isDelOpen: false, resMessage: response.data.message,uid:""});
            if (this.state.currentFolder) {
              this.fetchFolder(this.state.currentFolder);
            }
            else {
              this.getUserDirectories();
            }
         }
      });
   }

   fetchDeletedDocuments = function() {
      let authToken = this.getAuthToken();
      apiHandler.getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.fetchDeleted),{ Authorization: authToken })
      .then(response => {
        if (response.status === 200) {
           this.setState({deletedDocs: response.data.data.documents})
        }
      });
   }

   restoreDocument = function(uid) {
     let authToken = this.getAuthToken();
     apiHandler.postRequest((apiUrls.baseUrl.development + '/documents/' + uid + '/restore'),{ Authorization: authToken })
     .then(response => {
       if (response.status === 200) {
         this.setState({isResOpen: false, resMessage: response.data.message, uid:""});
         this.fetchDeletedDocuments();
         document.querySelectorAll('.checkbox').forEach(function (item) {
           item.checked = false;
         });
       }
     });
   }

   deleteDirectory = function(uid) {
      let authToken = this.getAuthToken();
      apiHandler.postRequest((apiUrls.baseUrl.development + '/directories/' + this.state.uid + '/delete'),{ Authorization: authToken })
      .then(response => {
         if (response.status === 200) {
            this.setState({isDelOpen: false, resMessage: response.data.message,uid:""});
            if (this.state.currentFolder) {
              this.fetchFolder(this.state.currentFolder);
            }
            else {
              this.getUserDirectories();
            }
         }
      });
   }

   fetchDeletedDirectories = function() {
      let authToken = this.getAuthToken();
      apiHandler.getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.fetchDeletedDirectories),{ Authorization: authToken })
      .then(response => {
        if (response.status === 200) {
           this.setState({deletedDirectories: response.data.data.directories})
        }
      });
   }

   restoreDirectory = function(uid) {
     let authToken = this.getAuthToken();
     apiHandler.postRequest((apiUrls.baseUrl.development + '/directories/' + uid + '/restore'),{ Authorization: authToken })
     .then(response => {
       if (response.status === 200) {
         this.setState({isResOpen: false, resMessage: response.data.message, uid:""});
         this.fetchDeletedDirectories();
       }
     });
   }

   handleDelete = function() {
      if (this.state.delMode == "document") {
         this.deleteDocument(this.state.uid);
      }
      else if (this.state.delMode == "directory") {
         this.deleteDirectory(this.state.uid);
      }
   }

   handleRestore = function() {
      if (this.state.resMode == "document") {
         this.restoreDocument(this.state.uid);
      }
      else if (this.state.resMode == "directory") {
         this.restoreDirectory(this.state.uid);
      }
   }

   setSetSelectAll(docArray) {
     if (this.state.mode == "trash") {
       if(this.state.deletedDocs == ''){
         return 'disabled'
       } else {
         return ''
       }
     }
     else {
       let directoriesLen = docArray.directories != undefined ? docArray.directories.length : 0
       let documentsLen = docArray.documents != undefined ? docArray.documents.length : 0
       if(directoriesLen === 0 && documentsLen === 0 ){
         return 'disabled'
       } else{
         return ''
       }
     }

   }


   fileUploadStatus = (status_uid, authToken) =>{
      return apiHandler.getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.uploadStatus + '/' + status_uid), { Authorization: authToken })
      .then(response => {
         const uploadStatusApiResponse = response;
            if (uploadStatusApiResponse.status === 200) {
               if(uploadStatusApiResponse.data.data.is_error){
                  this.setState({loading: false, uploadOption: "Replace"});
                  document.getElementById("createFolder").style.display = "none";
                  document.getElementById("modifyUpload").removeAttribute("style");
               }
               else if(uploadStatusApiResponse.data.data.is_completed){
                    this.setState({loading: false});
                     this.fetchFolder(this.state.parentSelected !== '' ? this.state.parentSelected : document.getElementById('parentFolder')[0].value);
                     this.closePopup();
               }
               else{
                  this.setState({loading: true});
                  this.fileUploadStatus(status_uid,authToken);
                  setTimeout(function () {
                  }, 3000)
               }
            }
         })
         .catch(err => {
            this.setState({loading: false});
            console.log("err", err);
            alert('Something went wrong....');
            this.props.router.push('/documents');
            return false;
         })

   }
   goToFolder = (e,val)=>{
      const  { newArr } = this.state
      this.fetchFolder(val.uid)
      var index = newArr && newArr.map((e)=> { return e.uid}).indexOf(val.uid);
      if(index === 0){
         newArr.splice(1);
      }
      else{
         newArr.splice(index+1,newArr.length);
      }
      this.setState({
         newArr
      })

   }
   backFolder = (e)=>{
      const  { newArr } = this.state;
      let lengthval;
      if(newArr.length >1){
         lengthval = newArr.length-2;
      }else{
         lengthval = newArr.length-1;
      }
      let uid = newArr[lengthval].uid;
      this.fetchFolder(uid)
      if(newArr.length > 1){
         newArr.pop();
      }else{
         newArr.splice(1);
      }
     
      this.setState({
         newArr
      })

   }

   renderBreadCrumList = ()=>{
      const { newArr } = this.state;
      return newArr && newArr.map((val)=>{
         return <span onClick={(e)=>this.goToFolder(e,val)} className="fldname" name={val.uid}> <span className="brad_divder">/</span> {val.name} </span>
      })
      
   }

   fetchFolderArr = (e,name,uid)=>{
      let x = []
      let obj = {}
      obj['name'] = name;
      obj['uid'] = uid;
      x.push(obj);
      this.setState({
         newArr : [...this.state.newArr,...x]
      })
   }
   customshowTabs = function (e) {
      let previousActiveLink = document.getElementsByClassName('activelist');
      let previousActiveDisplay = document.getElementById((previousActiveLink[0].getAttribute('data-conid')));
      previousActiveDisplay.setAttribute('style', 'display: none;');
      var folderDisplay = document.getElementById('FolderViewContainer');
      folderDisplay.setAttribute('style', 'display: none;');
      //previousActiveLink[0].classList.remove('activelist');
      let currentActiveLink = e.currentTarget;
      const dataConid = currentActiveLink.getAttribute('data-conid')
      let currentActiveDisplay = document.getElementById(dataConid);
      document.getElementById('bradecum').style.display = 'none';
      let  { newArr } = this.state
      newArr = [];  
      this.setState({
         newArr
      })
      if (dataConid == 'FolderContainer') {
         this.getUserDirectories();

      }
      currentActiveLink.className += 'activelist'
      currentActiveDisplay.setAttribute('style', '');
   }

   modifyUpload = function(e,option) {
      if (option != null) {
         this.validateFileName();
         if (document.getElementById("fileName").checkValidity()) {
            this.setState({newFileName: option + this.state.currentUploadPdf.slice(-4)}, this.renameUpload);
         }
      }
      else {
         this.renameUpload();
      }
   }

   renameUpload = function(e) {
      document.getElementById("modifyUpload").style.display = 'none';
      let list = new DataTransfer();
      if (this.state.uploadOption == "Rename") {
         let pdfFileObj = this.state.uploadFile;
         let pdfFileNew = undefined;
         for (let i=0;i<pdfFileObj.length;i++) {
            pdfFileNew = new File([pdfFileObj[i]], this.state.newFileName,{type: ['.png','.jpg','.jpeg'].some(substring=>pdfFileObj[0].name.includes(substring)) ? "image/png" : "application/pdf"});
            list.items.add(pdfFileNew);
         }
         document.getElementById("uploadPdfFile").files = list.files;
      }
      else {
         let pdfFileObj = this.state.uploadFile;
         for (let i=0;i<pdfFileObj.length;i++) {
            list.items.add(pdfFileObj[i]);
         }
         document.getElementById("uploadPdfFile").files = list.files;
      }
      this.createFolderOrUploadFile(e,"Upload File");
   }

   closeUploadAction = function() {
      document.getElementById("modifyUpload").style.display = 'none';
      this.setState({uploadOption: ""});
      document.getElementById("createFolder").removeAttribute("style");
   }

   validateFileName = function() {
      var fileName = document.getElementById("fileName");
      var regExp = /^[a-zA-Z][a-zA-Z0-9_]+$/
      if (fileName.value.trim() === "") {
         fileName.setCustomValidity("Enter file name");
      }
      else if (fileName.value.length < 3 || fileName.value.length > 32) {
         fileName.setCustomValidity("File name must be between 3 and 32 characters long");
      }
      else if (!regExp.test(fileName.value)) {
         fileName.setCustomValidity("Invalid file name");
      }
      else {
         fileName.setCustomValidity("");
      }
      document.querySelector(".fileName-error").innerHTML = fileName.validationMessage;
   }
   showAction = function (e) {
      var actionMenu = e.currentTarget.children[1];
      actionMenu.classList.remove('atitems-hide');
   }
   showdeletepopup = function (e){
      document.getElementById('deleteDoc').style.display="block";
   }
   hidedeletepopup = function (e){
      document.getElementById('deleteDoc').style.display="none";
      var elename = document.getElementsByClassName('acitems');
      elename.classList.add('atitems-hide');
   }
   handleDropdownEvent(id) {
      var dropDown = document.querySelectorAll('.acitems');
      document.getElementById(id).removeAttribute('style');
      document.getElementsByTagName("body")[0].addEventListener('click', function() {
         dropDown.forEach(item =>{
            item.style.display = 'none';
         });
      })
   }
   searchDocument() {
      var text = document.getElementById('searchInput').value.trim();
      if (text == "") {
         return false;
      }
      const authToken = this.state.loggedInUser['authToken'];
      let apiHeader = '';
      let form_data = new FormData();
      if (this.state.mode === "folders") {
         this.setState({searchMode: 'folders'});
      }
      else {
         this.setState({searchMode: 'trash'});
      }
      let endPoint = this.state.mode === "folders" ? apiUrls.endPoints.searchDocument : apiUrls.endPoints.searchTrash
      form_data.append('text', text);
      apiHeader = {
         Authorization: authToken,
         'Content-Type': 'multipart/form-data'
      }
      apiHandler
         .postRequest((apiUrls.baseUrl.development + endPoint), apiHeader, form_data)
         .then(response => {
            if (response.status === 200) {
               const apiResponse = response.data;
               if (apiResponse.code = 'success') {
                  this.setState({searchResult: response.data.data, mode: "search"});
                  localStorage.removeItem('searchText');
                  document.getElementById("FolderContainer").setAttribute("style","display: none");
                  document.getElementById("trashContainer").setAttribute("style","display: none");
                  document.getElementById("searchResultContainer").removeAttribute("style");
               }
            } 
            else {
               this.setState({searchResult: {documents: []}, mode: "search"});
               localStorage.removeItem('searchText');
                  document.getElementById("FolderContainer").setAttribute("style","display: none");
                  document.getElementById("trashContainer").setAttribute("style","display: none");
                  document.getElementById("searchResultContainer").removeAttribute("style");
            }
      });
   }

   clearSearchResult() {
      if (this.state.searchMode === "folders") {
         document.getElementsByClassName('activelist')[0].classList.remove('activelist');
         document.getElementsByClassName('bodywrapperleft')[0].children[0].children[0].classList.add('activelist');
         this.setState({searchResult: [], mode: 'folders'});
         document.getElementById("searchInput").value = "";
         document.getElementById("searchResultContainer").style.display = 'none';
         document.getElementById("FolderContainer").removeAttribute('style');
      }
      else {
         document.getElementsByClassName('activelist')[0].classList.remove('activelist');
         document.getElementsByClassName('bodywrapperleft')[0].children[0].children[2].classList.add('activelist');
         this.setState({searchResult: [], mode: 'trash'});
         document.getElementById("searchInput").value = "";
         document.getElementById("searchResultContainer").style.display = 'none';
         document.getElementById("trashContainer").removeAttribute('style');
      }
      
   }

   checkFilenameValidity(filename) {
      filename = filename.split('\\')[2];
      filename = filename.slice(0,filename.length-4);
      const special = ['/','\'','?','%','*',':','|','"','<','>',',',';','='];
      if (special.some(spec => filename.includes(spec))) {
         return false;
      }
      else {
         return true;
      }
   }

   convertToBase64(file) {
      var base64 = null;
      if (!file.type.match("image.*")) return;
      var reader = new FileReader();
      reader.onload = () => {
         this.setState({uploadImage: reader.result});
      };
      reader.readAsDataURL(file);
   }

   handleDropDown(name, uid) {
      if (name == "ROOT") {
         this.setState({parentSelected: uid });
      }
      else {
         this.setState({parentSelected: uid});
      }
   }

   render() {
      return (
         <React.Fragment>
          <div>
            <div className="search_txt">
                    <input type="text" className="cus_text" placeholder="Search Document" id="searchInput" />
                    <span className="serchicons" onClick={()=>{this.searchDocument()}}><i className="fa fa-search"></i></span>
                    <span className="clearSearch"><FontAwesomeIcon icon={faTimes} onClick={() => {document.getElementById("searchInput").value = ""}}></FontAwesomeIcon></span>
             </div>
            <div className="popupwrapper" id="createFolder" style={{ display: 'none' }}>
               <div className="actionbody">
                  <div className="poptitleHead">
                    <span className="poptitletxt">{this.state.upCrHeading}</span>
                    <span className="clsbtn float-right"><FontAwesomeIcon icon={faTimes} onClick={e=> {this.closePopup(e);}} /></span>
                  </div>
                  <div className="acttxtx">Select Parent folder</div>
                  <form>
                     <FormGroup className="folderSelect">
                        <Label for="parentFolder">Parent Folder</Label>
                        <Input type="select" name="select" id="parentFolder" onChange={e => {
                           this.handleDropDown(e.target[e.target.selectedIndex].text, e.target.value);
                        }}
                        disabled = {this.state.folderValue.length === 1  ? 'disabled' : ''}
                        >
                           {this.state.folderValue}
                        </Input>
                     </FormGroup>
                     <FormGroup>
                       <div className="folderpath">
                          <label className="fltxt" style={{paddingLeft: '10px'}}>Folder</label>
                          <div className="fldwrapper">
                             <span className="fldicons">
                                <FontAwesomeIcon icon={faFolder} />
                             </span>
                             <span>
                                {
                                   this.state.upCrHeading === 'Create Folder' ? (
                                      <div>
                                      <Input type="text" id="folderName" placeholder="Enter a Folder Name" style={{margin: '50'}} required={true}
                                         onChange={e => {
                                            this.setState({
                                               newFolderName: e.target.value
                                            });
                                            if (e.target.value == "") {
                                              document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
                                              document.getElementsByClassName('movebtn')[0].classList.add('disabled');
                                            }
                                            else {
                                              document.getElementsByClassName('movebtn')[0].removeAttribute('disabled');
                                              document.getElementsByClassName('movebtn')[0].classList.remove('disabled');
                                            }
                                         }}
                                      />
                                       <div className="response-error">{this.state.uploadOrCreateResMsg != "" ? this.state.uploadOrCreateResMsg.replace("Directory","Folder") : ""}</div>
                                       </div>
                                   )
                                      : (<div className="upload_files"><input multiple type="file" id='uploadPdfFile' required={true}
                                         onChange={e => {
                                            this.setState({
                                               currentUploadPdf: e.target.value
                                            })
                                            var fileObj= e.target.files;
                                            var currentfilindex =[];
                                            var fileExtension = ['pdf','png','jpg','jpeg']
                                            for(var i=0; i< fileObj.length; i++){
                                               currentfilindex.push(fileObj[i].name.split(/\.(?=[^\.]+$)/)[1]);
                                            }
                                            const contains = (first, second) => {
                                               const indexArray = first.map(el => {
                                                  return second.indexOf(el);
                                               });
                                               return indexArray.indexOf(-1) === -1;
                                            }
                                            var result_new =contains(currentfilindex, fileExtension);
                                            var pdf_count;
                                            var img_count;
                                            currentfilindex.sort();
                                            var current = null;
                                            var cnt = 0;
                                            for (var i = 0; i < currentfilindex.length; i++) {
                                                if (currentfilindex[i] != current) {
                                                    if (cnt > 0) {
                                                        
                                                        if(current == 'pdf'){
                                                         pdf_count = cnt;
                                                        }
                                                        if(current == 'png' || current == 'jpg' || current == 'jpeg'){
                                                         img_count = cnt;
                                                        }
                                                     }
                                                    current = currentfilindex[i];
                                                    cnt = 1;
                                                } else {
                                                    cnt++;
                                                }
                                            }
                                            if (cnt > 0) {
                                             if(current == 'pdf'){
                                                pdf_count = cnt;
                                               }
                                                if(current == 'png' || current == 'jpg' || current == 'jpeg'){
                                                img_count = cnt;
                                               }
                                            }
                                            if (e.target.value == "") {
                                              document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
                                              document.getElementsByClassName('movebtn')[0].classList.add('disabled');
                                              document.getElementsByClassName('movebtn')[0].removeAttribute('style');
                                              e.currentTarget.setCustomValidity("");
                                              document.querySelector('.msgWrap').innerHTML = "";
                                            }
                                            else if (!['.pdf','.png','.jpg','.jpeg'].includes(e.target.value.substring(e.target.value.length-4,e.target.value.length))) {
                                              document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
                                              document.getElementsByClassName('movebtn')[0].classList.add('disabled');
                                              document.getElementsByClassName('movebtn')[0].removeAttribute('style');
                                              e.currentTarget.setCustomValidity('Unsupported file format');
                                              document.querySelector('.msgWrap').innerHTML = "";
                                            }
                                            else if (!this.checkFilenameValidity(e.target.value)) {
                                             document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
                                             document.getElementsByClassName('movebtn')[0].classList.add('disabled');
                                             document.getElementsByClassName('movebtn')[0].setAttribute('style','margin-top: 50px;');
                                             e.currentTarget.setCustomValidity("The following characters are prohibited for use in a file name: / \\ ? % * : | \" < > . , ; = \n Please rename your file and try again.");
                                             document.querySelector('.msgWrap').innerHTML = "";
                                            }
                                            else if (pdf_count >=1 && img_count >= 1 ) {
                                             document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
                                             document.getElementsByClassName('movebtn')[0].classList.add('disabled');
                                             document.getElementsByClassName('movebtn')[0].setAttribute('style','margin-top: 50px;');
                                             e.currentTarget.setCustomValidity("Please select only image file.");
                                             document.querySelector('.msgWrap').innerHTML = "";
                                            }
                                            else if (pdf_count >1 && img_count == undefined ) {
                                             document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
                                             document.getElementsByClassName('movebtn')[0].classList.add('disabled');
                                             document.getElementsByClassName('movebtn')[0].setAttribute('style','margin-top: 50px;');
                                             e.currentTarget.setCustomValidity("Please select only onle pdf file.");
                                             document.querySelector('.msgWrap').innerHTML = "";
                                            }
                                            else if (document.getElementById('uploadPdfFile').files.length > 10) {
                                             document.getElementsByClassName('movebtn')[0].setAttribute('disabled','disabled');
                                             document.getElementsByClassName('movebtn')[0].classList.add('disabled');
                                             document.getElementsByClassName('movebtn')[0].setAttribute('style','margin-top: 50px;');
                                             e.currentTarget.setCustomValidity("You can't upload more than 10 files at a time.");
                                             document.querySelector('.msgWrap').innerHTML = "";
                                            }
                                            else if (['.png','.jpg','.jpeg'].some(substring=>e.target.value.includes(substring)) && fileObj.length > 1) {
                                             document.getElementsByClassName('movebtn')[0].removeAttribute('disabled');
                                             document.getElementsByClassName('movebtn')[0].classList.remove('disabled');
                                             document.getElementsByClassName('movebtn')[0].removeAttribute('style');
                                             e.currentTarget.setCustomValidity("");
                                             document.querySelector('.msgWrap').innerHTML = "Pages sequence can be changed later using drag and drop in Grid view.";
                                            }
                                            else {
                                              document.getElementsByClassName('movebtn')[0].removeAttribute('disabled');
                                              document.getElementsByClassName('movebtn')[0].classList.remove('disabled');
                                              document.getElementsByClassName('movebtn')[0].removeAttribute('style');
                                              e.currentTarget.setCustomValidity("");
                                              document.querySelector('.msgWrap').innerHTML = "";
                                            }
                                            document.querySelector("#uploadError").innerHTML = e.currentTarget.validationMessage;
                                         }} />
                                          <div className="invalid" id="uploadError" style={{marginTop: '25px'}}></div>
                                        </div>)
                                }
                             </span>
                          </div>
                       </div>
                      </FormGroup>
                      <div>
                        <div className="msgWrap"></div>
                        <Button className="movebtn" data-id="move"
                          onClick={(e) => {
                             this.createFolderOrUploadFile(e, this.state.upCrHeading === 'Create Folder' ? 'Create Folder'
                                : 'Upload');
                          }}>
                           {
                              this.state.upCrHeading === 'Create Folder' ? 'Create'
                                 : 'Upload'
                           }
                        </Button>
                      </div>
                  </form>
               </div>
            </div>
            <div className="popupwrapper uploadAction" id="modifyUpload" style={{ display: 'none' }}>
               <div className="actionbody">
                  <div className="poptitleHead">
                     <span className="poptitletxt">Filename already exists: {this.state.uploadFile.name}</span>
                     <span className="clsbtn float-right"><FontAwesomeIcon icon={faTimes} onClick={() => {this.closeUploadAction()}} /></span>
                  </div>
                  <form>
                     <div className="radio">
                        <label>
                        <input
                           type="radio"
                           name="uploadOption"
                           value="Replace"
                           className="uploadOption"
                           checked={this.state.uploadOption === "Replace"}
                           onChange={e => {
                              this.setState({
                                 uploadOption: e.target.value
                              });
                           }}
                        />
                        Replace uploaded file
                        </label>
                     </div>
                     <div className="radio">
                        <label>
                        <input
                           type="radio"
                           name="uploadOption"
                           value="Rename"
                           className="uploadOption"
                           checked={this.state.uploadOption === "Rename"}
                           onChange={e => {
                              this.setState({
                                 uploadOption: e.target.value
                              });
                           }}
                        />
                        Rename file
                        </label>
                     </div>
                        { this.state.uploadOption === "Rename" ? 
                           <FormGroup>
                           <div className="folderpath">
                                 <label className="fltxt" style={{paddingLeft: '10px'}}>Rename File</label>
                                 <div className="fldwrapper">
                                    <span className="fldicons">
                                       <FontAwesomeIcon icon={faFolder} />
                                    </span>
                                    <span>
                                       <input type="text" id="fileName" placeholder="Enter a File Name" required={true} className="form-control"/>
                                    </span>
                                    <div className="invalid fileName-error"></div>
                                 </div>
                              </div>
                           </FormGroup> : null
                        }
                        
                     <div>
                        <Button className="movebtn"
                           onClick={(e) => {this.modifyUpload(e,this.state.uploadOption === "Rename" ? document.getElementById('fileName').value : null)}}
                        >
                          OK
                        </Button>
                        <Button className="movebtn modify-btn"
                           onClick = {() => {this.closeUploadAction()}}
                          >
                          Cancel
                        </Button>
                      </div>
                  </form>
               </div>
            </div>
            <div className="docBody">
               <div className="documentmain-wrapper">
               
                  <div className="bodywrapper">
                     <div className="bodywrapperleft">
                        <ul>
                           <li data-conid="FolderContainer" className="activelist"
                              onClick={e => {
                                 this.showTabs(e);
                                 this.setState({mode: "folders",resMessage:""});
                                 document.getElementById("searchInput").value = "";
                                 document.getElementById("searchResultContainer").style.display = 'none';
                              }}>
                              <span className="listTxt"><FontAwesomeIcon icon={faFolder} /></span>Folders
                           </li>
                           <li data-conid="DocumentContainer"
                              onClick={e => {
                                 this.setState({mode: "documents",resMessage:""});
                                 document.getElementById("searchInput").value = "";
                                 document.getElementById("searchResultContainer").style.display = 'none';
                              }}><span class="listTxt"><i class="fa fa-file-o" aria-hidden="true"></i></span>Documents</li>
                           <li data-conid="trashContainer"
                              onClick={e => {
                                 this.showTabs(e);
                                 this.fetchDeletedDocuments();
                                 this.fetchDeletedDirectories();
                                 this.setState({mode: "trash",resMessage:""});
                                 document.getElementById("searchInput").value = "";
                                 document.getElementById("searchResultContainer").style.display = 'none';
                              }}>
                              <span className="listTxt"><FontAwesomeIcon icon={faTrash} /></span>Trash
                             </li>
                        </ul>
                     </div>
                     <div className="bodywrapperright">
                        <div className="iframewrapper">
                           <div className="doc_detailwrapper">
                              <div id="FolderContainer">
                                 <div className="bradecum" id="bradecum">
                                    <span className="leftarrownew" onClick={(e)=>this.backFolder(e)}><i className="fa fa-long-arrow-left" aria-hidden="true"></i></span>
                                     <span className="root_txt activelist" data-conid="FolderContainer"
                                    onClick={e => {
                                       this.customshowTabs(e);
                                       this.setState({mode: "folders",resMessage:""});
                                    }}> Root </span><div id="bradList">{this.renderBreadCrumList()}</div>
                                    
                                    <span className="minus-arrow"><i className="fa fa-minus" aria-hidden="true"></i></span>
                                    <span className="document_number" id="totalDocument"></span>
                                    <span className="doc_txt">Documents</span>
                                 </div>
                                 <div className="topdocheader" id="topdocheader">
                                    <div className="toptxt_title">Folder</div>
                                    <div className="newDoc openDocument" id='createFolder '
                                       onClick={e => {
                                          this.triggerPopup('Create Folder');
                                       }}>
                                       <span className="docplusiocns openDocument"><FontAwesomeIcon icon={faPlusCircle} /></span>New Folder</div>
                                    <div className="newDoc openDocument" id='uploadDocument'
                                       onClick={e => {
                                          this.triggerPopup('Upload Document');
                                       }}>
                                       <span className="docplusiocns"><FontAwesomeIcon icon={faPlusCircle} />
                                       </span>Upload Document</div>
                                       <div className="selectall">
                                          <label className="cus_ckechbox"
                                          >Select all
                                          <input type="checkbox" className='allSelectFolder'
                                            onClick={e => {
                                                            this.selectDeselectAll(e);
                                                           }}
                                                           disabled = {this.setSetSelectAll(this.state.directoryData)}
                                                           />
                                             <span className="checkmark"></span>
                                          </label>
                                       </div>
                                    </div>
                                    {
                                      (this.state.resMessage != "") ?
                                        (<div>
                                          <div className="alert alert-success alert-dismissible show" id="alertSuccess" role="alert">{this.state.resMessage}
                                          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true" onClick={e=>{this.setState({resMessage:""})}}>&times;</span>
                                          </button>
                                          </div>
                                         </div>
                                       ) : ''
                                    }

                                 {
                                    (this.state.directoryData.directories != undefined) ? (
                                       this.state.directoryData.directories.map(value =>
                                          <div className="filecontainer openDocument" key={value.uid}>
                                             <div className=" filelist openDocument">
                                                <div className="checkbonconatiner">
                                                   <label className="cus_ckechbox folderAndFileCheck">
                                                      <input type="checkbox"
                                                         onClick={e => {
                                                                this.deSelectAll(e)
                                                         }}/>
                                                      <span className="checkmark"></span>
                                                   </label>
                                                </div>
                                                <div className="filedetailecontainer">
                                                   <div className="folderimg"><FontAwesomeIcon icon={faFolder} /></div>
                                                   <div className="fldetails">
                                                      <a data-folder-uid={value.uid} data-is-protected={value.is_password_protected} data-is-locked={value.is_locked}

                                                         onClick={e => {
                                                            this.fetchFolder(value.uid);
                                                         }}>
                                                         <div className="filename" onClick={(e)=>this.fetchFolderArr(e,value.name,value.uid)}>{value.name}</div>
                                                      </a>

                                                      <div className="fileactivity"> </div>
                                                   </div>
                                                </div>
                                                <div className="actionbtn" onClick={e => {
                                             this.showAction(e);
                                          }}><i className="fa fa-ellipsis-v" aria-hidden="true" onClick={(e) => {this.handleDropdownEvent(value.uid);}}></i>
                                                   <div className="acitems atitems-hide" id={value.uid}>
                                                   <ul>
                                                         {/* <li><span className="aciconsicons"><i className="fa fa-file-o" aria-hidden="true"></i></span><a
                                                            href="images/demopdf.pdf" target="_blank">Open</a></li>
                                                         <li data-popupid="prewrapper" className="actBtn"><span className="aciconsicons"><i className="fa fa-files-o"
                                                            aria-hidden="true"></i></span>Preview</li>
                                                         <li data-popupid="move" className="actBtn"><span className="aciconsicons"><i className="fa fa-exchange"
                                                            aria-hidden="true"></i></span>Move</li>
                                                         <li><span className="aciconsicons" className="actBtn"><i className="fa fa-download"
                                                            aria-hidden="true"></i></span>Download</li> */}
                                                         <li  data-popupid="deleteDoc" className="actBtnlist" onClick={()=> {this.setState({isDelOpen: true,delMode:"directory",uid:value.uid})}}
                                          > <span className="aciconsicons" className="actBtn"><i
                                                            className="fa fa-trash" aria-hidden="true"></i></span>Trash</li>
                                                      </ul>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       )

                                    ) : ('')
                                 }
                                 {
                                    (this.state.directoryData.documents != undefined) ? (
                                       this.state.directoryData.documents.map(value =>
                                          <div className="filecontainer openDocument">
                                             <div className=" filelist openDocument">
                                                <div className="checkbonconatiner">
                                                   <label className="cus_ckechbox folderAndFileCheck">
                                                      <input type="checkbox"
                                                            onClick={e => {
                                                                 this.deSelectAll(e)
                                                               }}/>
                                                      <span className="checkmark"></span>
                                                   </label>
                                                </div>
                                                <div className="filedetailecontainer">
                                                   <div className="fldetails">
                                                     <div title="Show all versions">
                                                      <div className="folderimg"><FontAwesomeIcon icon={faFilePdf} /></div>
                                                       <span className="filename" id="fileDetails">
                                                       <a data-file-uid={value.uid}
                                                          onClick={e => {
                                                                this.openPdffile(value.uid, e);
                                                             }}>
                                                          <span>{value.original_file_name}</span>
                                                       </a>
                                                       </span>
                                                
                                                     </div>
                                                     <div className="fileactivity"> </div>
                                                   </div>
                                                </div>
                                                <div className="actionbtn" onClick={e => {
                                             this.showAction(e);
                                          }}><i className="fa fa-ellipsis-v" aria-hidden="true" onClick={(e) => {this.handleDropdownEvent(value.uid);}}></i>
                                                   <div className="acitems atitems-hide" id={value.uid}>
                                                   <ul>
                                                         {/* <li><span className="aciconsicons"><i className="fa fa-file-o" aria-hidden="true"></i></span><a
                                                            href="images/demopdf.pdf" target="_blank">Open</a></li>
                                                         <li data-popupid="prewrapper" className="actBtn"><span className="aciconsicons"><i className="fa fa-files-o"
                                                            aria-hidden="true"></i></span>Preview</li>
                                                         <li data-popupid="move" className="actBtn"><span className="aciconsicons"><i className="fa fa-exchange"
                                                            aria-hidden="true"></i></span>Move</li>
                                                         <li><span className="aciconsicons" className="actBtn"><i className="fa fa-download"
                                                            aria-hidden="true"></i></span>Download</li> */}
                                                         <li  data-popupid="deleteDoc" className="actBtnlist" onClick={()=> {this.setState({isDelOpen: true,delMode:"document",uid:value.uid})}}
                                          > <span className="aciconsicons" className="actBtn"><i
                                                            className="fa fa-trash" aria-hidden="true"></i></span>Trash</li>
                                                      </ul>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>

                                       )

                                    ) : ('')
                                 }
                              </div>
                              <div id="FolderViewContainer" style={{ display: 'none' }} >
                                 <div className="topdocheader">
                                    <div className="toptxt_title">Folder</div>
                                    <div className="newDoc openDocument" id='uploadDocument'
                                       onClick={e => {
                                          this.triggerPopup('Upload Document');
                                       }}>
                                       <span className="docplusiocns"><FontAwesomeIcon icon={faPlusCircle} />
                                       </span>Upload Document</div>
                                 </div>
                                 <div className="selectall">
                                    <label className="cus_ckechbox">Select all
                           <input type="checkbox" />
                                       <span className="checkmark"></span>
                                    </label>
                                 </div>
                                 {
                                    (this.state.directoryData.directories != undefined) ? (
                                       this.state.directoryData.directories.map(value =>
                                          <div className="filecontainer openDocument" key={value.uid}>
                                             <div className=" filelist openDocument">
                                                <div className="checkbonconatiner">
                                                   <label className="cus_ckechbox folderAndFileCheck">
                                                      <input type="checkbox"
                                                         onClick={e => {
                                                                this.deSelectAll(e)
                                                         }}/>
                                                      <span className="checkmark"></span>
                                                   </label>
                                                </div>
                                                <div className="filedetailecontainer">
                                                   <div className="folderimg"><FontAwesomeIcon icon={faFolder} /></div>
                                                   <div className="fldetails">
                                                      <a data-folder-uid={value.uid} data-is-protected={value.is_password_protected} data-is-locked={value.is_locked}

                                                         onClick={e => {
                                                            this.fetchFolder(value.uid);
                                                         }}>
                                                         <div className="filename" >{value.name}</div>
                                                      </a>

                                                      <div className="fileactivity"> </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       )

                                    ) : ('')
                                 }
                                 {
                                    (this.state.directoryData.documents != undefined) ? (

                                       this.state.directoryData.documents.map(value =>
                                          <div className="filecontainer">
                                             <div className=" filelist openDocument">
                                                <div className="checkbonconatiner">
                                                   <label className="cus_ckechbox">
                                                      <input type="checkbox" />
                                                      <span className="checkmark"></span>
                                                   </label>
                                                </div>
                                                <div className="filedetailecontainer">
                                                   <div className="folderimg"><FontAwesomeIcon icon={faFilePdf} /></div>
                                                   <div className="fldetails">
                                                     <span className="filename" id="fileDetails">
                                                       <a data-file-uid={value.uid}
                                                          onClick={e => {
                                                                this.openPdffile(value.uid, e);
                                                             }}>
                                                          <span>{value.original_file_name}</span>
                                                       </a>
                                                     </span>
                                                     <span className="deleteDocIcon"><FontAwesomeIcon icon={faTimes} title="Delete Document" onClick={()=> {this.setState({isDelOpen: true,uid:value.uid})}}/></span>
                                                     <div className="fileactivity"> </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       )

                                    ) : ('')
                                 }
                              </div>
                              <div id="searchResultContainer" style={{display: 'none'}}>
                                 <div className="searchTab"><span className="searchtxt">Search Result</span><span className="clearSearchBtn" onClick={() => {this.clearSearchResult()}}>Clear Search Result</span></div>
                                 {
                                    (this.state.searchResult.documents  != undefined) ? (
                                       (this.state.searchResult.documents.length == 0) ? (
                                          <h6>No document found</h6>
                                       ) :
                                       (this.state.searchResult.documents.map(value => 
                                          <div className="filecontainer openDocument">
                                                   <div className=" filelist openDocument">
                                                       
                                                       <div className="filedetailecontainer">
                                                          <div className="fldetails">
                                                            <div>
                                                             <div className="folderimg"><FontAwesomeIcon icon={faFilePdf} /></div>
                                                             { (value.is_deleted === false) ? (
                                                                <span className="filename" id="fileDetails"
                                                                onClick={e => {
                                                                   this.openPdffile(value.uid, e);
                                                                }}
                                                             >{value.original_file_name} ( <span className=""><FontAwesomeIcon icon={faFolder}/> {value.directory.name}</span>)
                                                              </span>
                                                             ) : (
                                                               <span className="filename" id="fileDetails">{value.original_file_name} ( <span className=""><FontAwesomeIcon icon={faFolder}/> {value.directory.name}</span>)
                                                             </span>
                                                             ) }
                                                             
                                                            </div>
                                                          </div>
                                                       </div>
                                                   </div>
                                               </div>
                                       ))
                                    ) : ('')
                                 }
                              </div>
                              <div id="renameDocContainer" style={{ display: 'none' }}>
                                 <div className="topdocheader">
                                    <div className="newDoc"><span className="docplusiocns"><FontAwesomeIcon icon={faPlusCircle} /></span>Upload Document</div>
                                 </div>
                                 <div className="selectall">
                                    <label className="cus_ckechbox">Select all
                           <input type="checkbox" />
                                       <span className="checkmark"></span>
                                    </label>
                                 </div>
                                 <div className="filecontainer">
                                    <div className=" filelist openDocument">
                                       <div className="checkbonconatiner">
                                          <label className="cus_ckechbox">
                                             <input type="checkbox" />
                                             <span className="checkmark"></span>
                                          </label>
                                       </div>
                                       <div className="filedetailecontainer">
                                          <div className="fileimg"><FontAwesomeIcon icon={faFile} /></div>
                                          <div className="fldetails">
                                             <div className="filename">PDF File Longer Name</div>
                                             <div className="fileactivity"> </div>
                                          </div>
                                       </div>
                                       <div className="actionbtn"
                                          onClick={e => {
                                             this.showActionItems(e);
                                          }}>
                                          <FontAwesomeIcon icon={faEllipsisV} />
                                       </div>
                                    </div>
                                    <div className=" filelist openDocument">
                                       <div className="checkbonconatiner">
                                          <label className="cus_ckechbox">
                                             <input type="checkbox" />
                                             <span className="checkmark"></span>
                                          </label>
                                       </div>
                                       <div className="filedetailecontainer">
                                          <div className="fileimg"><FontAwesomeIcon icon={faFile} /></div>
                                          <div className="fldetails">
                                             <div className="filename">PDF File Longer Name</div>
                                             <div className="fileactivity"> </div>
                                          </div>
                                       </div>
                                       <div className="actionbtn"
                                          onClick={e => {
                                             this.showActionItems(e);
                                          }}>
                                          <FontAwesomeIcon icon={faEllipsisV} />
                                       </div>
                                    </div>
                                    <div className=" filelist openDocument">
                                       <div className="checkbonconatiner">
                                          <label className="cus_ckechbox">
                                             <input type="checkbox" />
                                             <span className="checkmark"></span>
                                          </label>
                                       </div>
                                       <div className="filedetailecontainer">
                                          <div className="fileimg"><FontAwesomeIcon icon={faFile} /></div>
                                          <div className="fldetails">
                                             <div className="filename">PDF File Longer Name</div>
                                             <div className="fileactivity"> </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className=" filelist openDocument openDocument">
                                       <div className="checkbonconatiner">
                                          <label className="cus_ckechbox">
                                             <input type="checkbox" />
                                             <span className="checkmark"></span>
                                          </label>
                                       </div>
                                       <div className="filedetailecontainer">
                                          <div className="fileimg"><FontAwesomeIcon icon={faFile} /></div>
                                          <div className="fldetails">
                                             <div className="filename">PDF File Longer Name</div>
                                             <div className="fileactivity"> </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div id="trashContainer" style={{ display: 'none' }}>
                                 <div className="topdocheader">
                                    <div className="toptxt_title">Trash</div>
                                    <div className=" filelist openDocument">
                                    {
                                   (this.state.resMessage != "") ?
                                     (<div>
                                       <div className="alert alert-success alert-dismissible show" id="alertSuccess" role="alert">{this.state.resMessage}
                                       <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                         <span aria-hidden="true" onClick={e=>{this.setState({resMessage:""})}}>&times;</span>
                                       </button>
                                       </div>
                                      </div>
                                    ) : ''
                                    }
                                    {
                                       (this.state.deletedDirectories != "") ? (
                                         <div>
                                           {
                                            this.state.deletedDirectories.map(value =>
                                              <div>
                                                 <div className="filecontainer openDocument">
                                                   <div className=" filelist openDocument">
                                                       <div className="checkbonconatiner">
                                                          <label className="cus_ckechbox folderAndFileCheck">
                                                             <input type="checkbox" className="checkbox"
                                                                   onClick={e => {
                                                                        this.deSelectAll(e)
                                                                      }}/>
                                                             <span className="checkmark"></span>
                                                          </label>
                                                       </div>
                                                       <div className="filedetailecontainer">
                                                          <div className="fldetails">
                                                            <div>
                                                             <div className="folderimg"><FontAwesomeIcon icon={faFolder} /></div>
                                                             <span className="filename" id="fileDetails">{value.name}</span>
                                                             <span><FontAwesomeIcon icon={faUndo} title="Restore Document" className="restoreDocIcon" onClick={()=> {this.setState({isResOpen: true, resMode:"directory",uid: value.uid})}}/></span>
                                                            </div>
                                                          </div>
                                                       </div>
                                                   </div>
                                               </div>
                                              </div>
                                            )}
                                          </div>

                                       ) : ('')
                                     }
                                       {
                                       (this.state.deletedDocs != "") ? (
                                         <div>
                                           <div className="selectall">
                                              <label className="cus_ckechbox">Select all
                                              <input type="checkbox" className="checkbox"
                                                onClick={e => {
                                                                this.selectDeselectAll(e);
                                                               }}
                                                               disabled = {this.setSetSelectAll(this.state.deletedDocs)}
                                                               />
                                                 <span className="checkmark"></span>
                                              </label>
                                           </div>
                                           {
                                            this.state.deletedDocs.map(value =>
                                              <div>
                                                 <div className="filecontainer openDocument">
                                                 <div className=" filelist openDocument">
                                                       <div className="checkbonconatiner">
                                                          <label className="cus_ckechbox folderAndFileCheck">
                                                             <input type="checkbox" className="checkbox"
                                                                   onClick={e => {
                                                                        this.deSelectAll(e)
                                                                      }}/>
                                                             <span className="checkmark"></span>
                                                          </label>
                                                       </div>
                                                       <div className="filedetailecontainer">
                                                          <div className="fldetails">
                                                            <div>
                                                             <div className="folderimg"><FontAwesomeIcon icon={faFilePdf} /></div>
                                                             <span className="filename" id="fileDetails">{value.original_file_name}</span>
                                                             <span><FontAwesomeIcon icon={faUndo} title="Restore Document" className="restoreDocIcon" onClick={()=> {this.setState({isResOpen: true, resMode: "document", uid: value.uid})}}/></span>
                                                            </div>
                                                          </div>
                                                       </div>
                                                   </div>
                                               </div>
                                              </div>
                                            )}
                                          </div>

                                       ) : ('')
                                     }
                                    </div>
                                 </div>

                                 <div className="filecontainer">
                                 
                                 </div>
                              </div>
                              {
                                ((this.state.deletedDocs.length == 0 && this.state.deletedDirectories.length == 0) && this.state.mode == "trash") ?
                                (
                                  <div id="emptytrashconatient">
                                     <div className="emptytrashconatientbody">
                                        <span className="empTricon"><FontAwesomeIcon icon={faTrash} /></span>
                                        <p className="empteTxt">When you delete a document, that document will be moved to the Trash Bin.If you'd like to edit a document that is located in the Trash Bin, first move that document to another folder. To permanently delete a document from your PDF filler account, delete it from your Trash Bin.</p>
                                     </div>
                                  </div>
                                ) : ('')
                              }
                              <Modal isOpen={this.state.isDelOpen} id="delModal">
                           <ModalHeader>Delete {this.state.delMode === "directory" ? "Folder" : "Document"}</ModalHeader>
                                <ModalBody>
                                  {this.state.delMode === "directory" ? "Are you sure, want to delete this folder & its contents?" :
                                  "Do you want to delete this document?" }
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="primary" onClick={()=>{this.handleDelete()}}>OK</Button>
                                  <Button color="secondary" onClick={()=>{this.setState({isDelOpen: false,uid:""})}}>Cancel</Button>
                                </ModalFooter>
                              </Modal>
                              <Modal isOpen={this.state.isResOpen} id="restoreModal">
                                <ModalHeader>Restore {this.state.resMode === "directory" ? "Folder" : "Document"}</ModalHeader>
                                <ModalBody>
                                 {this.state.resMode === "directory" ? "Are you sure, want to restore this folder & its contents?" :
                                  "Do you want to restore this document?" }
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="primary" onClick={()=>this.handleRestore()}>OK</Button>
                                  <Button color="secondary" onClick={()=>{this.setState({isResOpen: false})}}>Cancel</Button>
                                </ModalFooter>
                              </Modal>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {
               this.state.loading == true ? 
               (<div className="uploadLoader">
               <div style={{'textAlign': 'center', 'padding-top': '200px'}}><Loader type="Bars" color="#00BFFF" height={80} width={80} /></div>
               </div>) : null
            }
         </div>
         </React.Fragment>

      );
   }
}
export default Document;

import React, { PureComponent } from "react";
import "../../../config/import";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import './card.scss';
import './payment_page.css';
import debitCard from '../../../assets/images/debitCard.jpg';


class Payment extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            apiResponse: [],
            paymentMethod: '',
            isData: false,
        };
    }

    addNewCard(e) {
        this.props.router.push({pathname: '/payment/add'});
    }


    fetchPaymentMethod = function () {
        const user = JSON.parse(localStorage.getItem('User'));
        if (user === (undefined || null)) {
            this.props.router.push('/');
            return false;
        }
        const authToken = user['authToken'];
        apiHandler
            .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.paymentMethod), { Authorization: authToken })
            .then(response => {
                if (response.status == 200) {
                    this.setState({
                        paymentMethod: response.data.data.payment_methods,
                        isData: true
                    })
                } else {
                    this.props.router.push('/subscription');
                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }
    componentDidMount() {
        this.fetchPaymentMethod();
    }

    render() {
        return (
            <div className="paymentwrapper">
                <div className="paymentcontant">
                    <div className="paymentbody">
                        {
                            this.state.paymentMethod != '' ? (
                                this.state.paymentMethod.map(value =>
                                    <div className="paymentitems" key='value'>
                                        <div className="imgcontaniner"><span className="carimg"><img src={debitCard} className="img-responsive" /></span></div>
                                        <div className="paymentinfocontainer">
                                            <div className="carttitletxt">Cards</div>
                                            <div className="cardnumber">XXXX-XXXX-XXXX-{value.card_last4}</div>
                                            <div className="paymentinfo">
                                                <div className="carholdernatxt">Card Holder</div>
                                                <div className="carholname">{value.name}</div>
                                            </div>
                                            <div className="paymentinfo">
                                                <div className="carholdernatxt">Expires</div>
                                                <div className="carholname">{value.card_exp_month} / {value.card_exp_year}
                                           </div>
                                                <div className="customradio">
                                                    <label className="customlevel"><input type="radio" name="radio" /><span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                ) : ('')
                
                            }
                
                        <div className="addcard"><input type="button" className="addcardbtn" value="ADD CARD" onClick={e => this.addNewCard(e) }/></div>
                    </div>
                </div>
            </div>
                    )
                           
                    }
    }
                    
export default Payment;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/js/frontend";
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import {Router, browserHistory} from 'react-router';
import store from './redux/store';
import {Provider} from 'react-redux';
import {syncHistoryWithStore} from 'react-router-redux';
import routes from './config/routes';
import Header from './frontend/containers/header';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Footer from './frontend/views/shared/footer/footerComponent';
const history = syncHistoryWithStore(browserHistory, store);


ReactDOM.render(
<BrowserRouter>
 <Switch>
 <Provider store={store}>
    <React.Fragment>
    <Header />
        <Router history={history} routes={routes} />
    <Footer/>
        </React.Fragment>
    </Provider>
 </Switch>
</BrowserRouter> ,
    document.getElementById('root')
    );
registerServiceWorker();
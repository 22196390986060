import { connect } from 'react-redux';
import Payment from '../views/subscription/paymentComponent';
import { incrementCount } from '../../redux/actions/authentication';

const mapStateToProps = (state) => ({
    count : state.authentication.count
});

const mapDispatchToProps = (dispatch) => ({
    incrementCount : () => dispatch(incrementCount())
});

const Cards = connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);

export default Cards;
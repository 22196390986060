import React, { PureComponent } from "react";
import "../../../config/import";
import {
  Form,
  FormGroup,
} from "reactstrap";
import loginBg from "../../../assets/images/login-bg.jpg";
import logoLogin from "../../../assets/images/logo.jpg";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import "../../../assets/styles/login.css";
import  { Redirect } from 'react-router-dom';

class ForgetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showVerifyEmail: false,
      showForgetPasswordBox: true,
      forgetPassword_msg: '',
      recover_email: this.props.location.state != undefined ? this.props.location.state.email : '',
      callFrom: 'reset'
    };
    if (localStorage.getItem("User")) {
      this.props.router.push('/documents');
    }
    else {
      document.body.classList.add('hide-leftnav');
    }
  }
  componentDidMount() {
    this.setState({
      verification_msg: "",
      recmailId_error_message: "",
      reset_msg: ""
    });
  }
  checkInput(e) {
    let target = e.currentTarget;
    if (target.id === "recmailId" && document.getElementById('recmailId').value == '') {
      target.setCustomValidity("Enter your email");
      document.querySelector("#recmailIdEmailError").innerHTML = target.validationMessage;
      return false;
    }
    if(!document.getElementById('recmailId').value.includes('@') || !document.getElementById('recmailId').value.includes('.') || e.currentTarget.value.split('@')[0].trim() == ''){
      target.setCustomValidity("Please enter valid email address");
      document.querySelector("#recmailIdEmailError").innerHTML = target.validationMessage;
      return false;
    }
  }
  resetPassword = function(e) {
    this.btn.setAttribute("disabled", "disabled");
    const paramData = this.state;
    const apiData = {
      email: paramData.recover_email,
    }
    var emilId = document.getElementById('recmailId').value;
    if(document.getElementById('recmailId').value == ''){
      document.querySelector("#recmailIdEmailError").innerHTML = "Enter your email";
      this.refs.btn.removeAttribute("disabled");
      return false;
    }
    else{
      document.querySelector("#recmailIdEmailError").innerHTML = "";
    }
    if(!document.getElementById('recmailId').value.includes('@') || !document.getElementById('recmailId').value.includes('.') || emilId.split('@')[0].trim() == ''){
      document.querySelector("#recmailIdEmailError").innerHTML = "Please enter valid email address";
      this.refs.btn.removeAttribute("disabled");
      return false;
    }
    else{
      document.querySelector("#recmailIdEmailError").innerHTML = "";
    }
    localStorage.setItem('forgetPass', paramData.email);
    let api_url = "";
    if(this.props.callFrom) {
      api_url = apiUrls.baseUrl.development + apiUrls.endPoints.resendPasswordToken
    } else {
      api_url = apiUrls.baseUrl.development + apiUrls.endPoints.forgetPassword
    }
    apiHandler
      .postRequest(api_url, {}, apiData)
      .then(response => {
        if(response.status == '403' || response.status == '200' ) {
          this.props.router.push({pathname: '/login', state:{response_msg: response.data.message}});
        }
        else if(response.status !== '200' && response.status !== '403') {
          if(response.data.message == 'Invalid Email Address'){
            document.querySelector("#recmailIdEmailError").innerHTML = response.data.message;
            this.refs.btn.removeAttribute("disabled");
            return false;
          }
         
        } else {
          this.setState({
            showForgetPasswordBox: false,
            forgetPassword_msg: response.data.message
          });
          this.refs.btn.removeAttribute("disabled");
        }
      })
      .catch(err => {
        console.log("err", err);
      });
      e.preventDefault();
  }


  render() {
    let alert;
    if (this.state.forgetPassword_msg == "Invalid Email Address") {
      alert = <span className='alert-danger'>{this.state.forgetPassword_msg}</span>
    }
    else {
      alert = <span className='alert-primary'>{this.state.forgetPassword_msg}</span>
    }
    return (
      <React.Fragment>
       <div>
          {
            this.state.showForgetPasswordBox
            ?
            <div>
               <div className="mainloginWrapper">
                <div className="logo"><img src={logoLogin} className="img-responsive" /></div>
                <div className="innerwrapper">
                  <div className="recoverywrapper">
                      <h2 className="logtxt">Reset Password</h2>
                      {alert}
                      <p className="lohptxt recloginlohptxt"></p>
                      <div className="form-group">
                          <input type="text" className="form-control" id="recmailId" name="recmailId" placeholder="Email Address" onBlur={e => this.checkInput(e)} value={this.state.recover_email}
                          onChange={e => {
                            this.setState({ recover_email: e.target.value });
                          }}/>
                          <div className="invalid" id="recmailIdEmailError"></div>
                      </div>


                      <div className="form-group text-center">
                          <button ref={btn => { this.btn = btn; }} type="submit" className="logBtn" id="recBtn"
                          onClick={e => {
                            this.resetPassword(e);
                          }}>Reset Password</button>
                      </div>
                      <div className="regtxt">Return to <a href="/login" className="linkbtn clicklink">Log in</a></div>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
          <div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgetPassword;

import { connect } from 'react-redux';
import ForgetPasswordComponent from '../views/forget_password/forgetPasswordComponent';
import { incrementCount } from '../../redux/actions/authentication';

const mapStateToProps = (state) => ({
    count : state.authentication.count
});

const mapDispatchToProps = (dispatch) => ({
    incrementCount : () => dispatch(incrementCount())
});

const ForgetPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPasswordComponent);

export default ForgetPassword;
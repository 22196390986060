import React, { PureComponent } from "react";
import { withRouter } from 'react-router-dom';
import "../../../config/import";
import "./Unsubscrition.css";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import unsubscribepage from "../../../assets/images/unsubscribepage.gif";

class Unsubscribe extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            apiResponse: [],
            subcriptionModalToggle: 'none'
        };
    }
    componentDidMount() {
    }
    toggleUnsubscribleModal(e) {
        let modalVal = '';
        if (e.currentTarget.className === "unscbtn") {
            modalVal = ''
        } else {
            modalVal = 'none'
        }
        this.setState({
            subcriptionModalToggle: modalVal
        });

    }
    unsubscribePlan(e) {
        const user = JSON.parse(localStorage.getItem('User'));
        if (user) {
            const authToken = user['authToken'];
            apiHandler
                .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.unsubscribe), { Authorization: authToken })
                .then(response => {
                    if (response.status == 200) {
                        alert('Successfully unsubscribed');
                    } else {
                        alert(response.data.message);
                    }

                })
                .catch(err => {
                    console.log("err", err);
                });
        } 
        this.props.router.push('/');

    }



    render() {
        return (
            <div>
                <div className="subpopup" style={{ display: this.state.subcriptionModalToggle }} >
                    <div className="subpopupbodu">

                        <span className="closebtn"><i className="fa fa-close"></i></span>
                        <div className="sub_img"><img src={unsubscribepage} className="img-responsive" /></div>
                        <div className="subtxt">Do you want to Unsubscribe.</div>
                        <div className="btnaction">
                            <span className="customun_scbtn" onClick={e => {
                                this.unsubscribePlan(e);

                            }}
                            >Unsubscribe</span>
                            <span className="unsubCancel" onClick={e => {
                                this.toggleUnsubscribleModal(e);

                            }}>Cancel</span>
                        </div>
                    </div>
                </div>
                <div className="unscWrapper">
                    <div className="unsccontainer">
                        <h2 className="untitletxt">Select your reason for Unsubscribing</h2>
                        <ul>
                            <li><label className="custome_radio">  I don't remember Subscribing.
         <input type="radio" name="radio" />
                                <span className="checkmark"></span>
                            </label>
                            </li>
                            <li><label className="custome_radio"> Your products are too expensive.
         <input type="radio" name="radio" />
                                <span className="checkmark"></span>
                            </label>
                            </li>
                            <li><label className="custome_radio">
                                <input type="radio" name="radio" />
                                <span className="checkmark"></span>
                            </label>
                            </li>
                            <li><label className="custome_radio"> I was just exploring.
         <input type="radio" name="radio" />
                                <span className="checkmark"></span>
                            </label>
                            </li>
                            <li><label className="custome_radio"> Got a better product.
         <input type="radio" name="radio" />
                                <span className="checkmark"></span>
                            </label>
                            </li>
                        </ul>
                        <div className="unbtn-wrapper"><span className="unscbtn" onClick={
                            e => {
                                this.toggleUnsubscribleModal(e)
                            }
                        }>Submit</span></div>
                    </div>

                </div>
            </div>


        );
    }
}

export default withRouter(Unsubscribe);

import { connect } from 'react-redux';
import SignupComponent from '../views/login_signup/signupComponent';
import { incrementCount, setUserLogin } from '../../redux/actions/authentication';

const mapStateToProps = (state) => ({
    count : state.authentication.count
});

const mapDispatchToProps = (dispatch) => ({
    incrementCount : () => dispatch(incrementCount()),
    setUserLogin : () => dispatch(setUserLogin())
});

const Signup = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupComponent);

export default Signup;

import {applyMiddleware, createStore, compose} from 'redux';
import reducer from '../reducers';
import thunkMiddleware from 'redux-thunk';

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
}

const store = compose(applyMiddleware(...middlewares))(createStore)(reducer);
export default store;

import React, { PureComponent } from "react";
import "../../../config/import";
import apiHandler from "../../../utilities/handlers/apiHandler";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import apiUrls from "../../../constants/apiUrls";
import './MyProfile.css';
import Location from "../shared/location/locationComponent";

class MyProfile extends PureComponent {
    constructor(props,context) {
        super(props,context);
        this.state = {
            apiResponse: [],
            customer: '',
            fName: "",
            mName: "",
            lName: "",
            email:"",
            addrLine1:"",
            addrLine2:"",
            isData: false,
            city: "",
            stateName: "",
            country: "",
            postal: "",
            resMessage: ""
        };
        this.enableForm = this.enableForm.bind(this);
        this.disableForm = this.disableForm.bind(this);
        this.checkUpdateInput = this.checkUpdateInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.fetchCustomer();
    }

    handleChange(e) {
      if (e.keyCode == 32) {
        console.log("$$$$$$$$$$$$$$$$$");
        e.preventDefault();
      }
    }
    
    fetchCustomer = function () {
        const user = JSON.parse(localStorage.getItem('User'));
        if(user === (undefined || null)) {
            this.props.router.push('/');
            return false;
        }
        const authToken = user['authToken'];
        apiHandler
            .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.subscriptions), { Authorization: authToken })
            .then(response => {
            if (response.status == 200) {
                let name = response.data.data.customer.name.split(' ')
                this.setState({ customer: response.data.data.customer,
                                fName: this.titleCase(name[0]),
                                mName: this.titleCase(name[1]),
                                lName: this.titleCase(name[2]),
                                email: response.data.data.customer.email,
                                addrLine1: this.titleCase(response.data.data.customer.address1),
                                addrLine2: this.titleCase(response.data.data.customer.address2),
                                city: response.data.data.customer.city != null ? response.data.data.customer.city : '',
                                stateName: response.data.data.customer.state != null ? response.data.data.customer.state : '',
                                country: response.data.data.customer.country != null ? response.data.data.customer.country : 'US',
                                postal: response.data.data.customer.postal,
                                isData: true})
            } else {
                this.props.router.push('/subscription');
            }
            })
            .catch(err => {
            console.log("err", err);
            });
    }

    titleCase = function(str) {
      if (str) {
        str = str.charAt(0).toUpperCase() + str.substr(1);
      }
      else {
        str = "";
      }
      return str;
    }

    enableForm = function() {
      document.getElementsByTagName('fieldset')[0].removeAttribute('disabled');
    }

    disableForm = function() {
      document.getElementsByTagName('fieldset')[0].setAttribute('disabled',true);
    }

    checkUpdateInput = function(e) {
      e.preventDefault();
      e.stopPropagation();
      var fName = document.getElementById('fName');
      var mName = document.getElementById('mName');
      var lName = document.getElementById('lName');
      var email = document.getElementById('email');
      var add1 = document.getElementById('add1');
      var country = document.getElementById('country');
      var state = document.getElementById('state');
      var city = document.getElementById('city');
      var zip = document.getElementById('zip');
      if (fName.value.trim() == "") {
        fName.setCustomValidity("Required Field");
      }
      else {
        fName.setCustomValidity("");
      }
      if (mName.value.trim() == "") {
        this.setState({mName: ""})
      }
      if (lName.value.trim() == "") {
        lName.setCustomValidity("Required Field");
      }
      else {
        lName.setCustomValidity("");
      }
      if (email.value.trim() == "") {
        email.setCustomValidity("Required Field");
      }
      else {
        email.setCustomValidity("");
      }
      if (add1.value.trim() == "") {
        add1.setCustomValidity("Required Field");
      }
      else {
        add1.setCustomValidity("");
      }
      if (country.value == "") {
        country.setCustomValidity("Required Field");
      }
      else {
        country.setCustomValidity("");
      }
      if (state.value == "") {
        state.setCustomValidity("Required Field");
      }
      else {
        state.setCustomValidity("");
      }
      if (city.value == "") {
        city.setCustomValidity("Required Field");
      }
      else {
        city.setCustomValidity("");
      }
      if (zip.value.trim() == "") {
        zip.setCustomValidity("Required Field");
      }
      else {
        if (this.state.country == 'US' && (zip.value.length < 5 || zip.value.length > 5)) {
          zip.setCustomValidity("Invalid Zipcode");
        }
        else if (this.state.country == 'IN' && (zip.value.length < 6 || zip.value.length > 6)) {
          zip.setCustomValidity("Invalid Zipcode");
        }
        else {
          zip.setCustomValidity("");
        }
      }
      document.querySelector('#fNameError').innerHTML = fName.validationMessage;
      document.querySelector('#lNameError').innerHTML = lName.validationMessage;
      document.querySelector('#emailError').innerHTML = email.validationMessage;
      document.querySelector('#add1Error').innerHTML = add1.validationMessage;
      document.querySelector('#countryError').innerHTML = country.validationMessage;
      document.querySelector('#stateError').innerHTML = state.validationMessage;
      document.querySelector('#cityError').innerHTML = city.validationMessage;
      document.querySelector('#zipError').innerHTML = zip.validationMessage;

      if (!document.getElementsByTagName('form')[0].checkValidity()) {
        return false;
      }
      else {
        const user = JSON.parse(localStorage.getItem('User'));
        const authToken = user['authToken'];
        let data = {
            name: this.state.fName.trim() + ' ' + this.state.mName.trim() + ' ' + this.state.lName.trim(),
            email: this.state.email,
            address1: this.state.addrLine1,
            address2: this.state.addrLine2,
            city: this.state.city,
            state: this.state.stateName,
            country: this.state.country,
            postal: this.state.postal
        }
        apiHandler
            .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.updateCustomer), { Authorization: authToken }, data)
            .then(response => {
                this.fetchCustomer();
                this.setState({resMessage: response.data.message});
                this.disableForm();
            })
            .catch(err => {
               console.log("err", err);
               return false;
            })
      }
    }

    locationCallback = (locationData) => {
      this.setState({city: locationData.city, stateName: locationData.stateName, country: locationData.country, postal: locationData.postal})
    }

    render() {
        return (
        <div>
          <div>
            {
              this.state.isData === true ? (
              <div>

              <div className="userinformationform prifileform">
              <div className="alert alert-success alert-dismissible show" id="alertSuccess" role="alert" style={{display: this.state.resMessage != '' ? '' : 'none'  }}>
              {this.state.resMessage != '' ? this.state.resMessage : ''}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true" onClick={e=>{this.setState({resMessage:""})}}>&times;</span>
                </button>
              </div>
                  <form className="form-horizontal">
                    <fieldset disabled="disabled">
                            <div id="legend">
                              <legend className="">User Information
                                <span className="controls edit-profile">
                                  <FontAwesomeIcon icon={faEdit} onClick={this.enableForm} />
                                </span>
                              </legend>

                            </div>
                            <div className="control-group">
                              <label className="control-label"  htmlFor="fName">First Name</label>
                              <div className="controls">
                                <input type="text" id="fName" name="fName" placeholder=""
                                value={this.state.fName}
                                onChange={e => {
                                  this.setState({
                                      fName: e.target.value
                                  });
                                }}
                                onKeyDown={e =>{
                                  this.handleChange(e);
                                }}
                                className="input-xlarge" />
                                <div className="invalid" id="fNameError" ></div>
                              </div>
                            </div>

                            <div className="control-group">
                              <label className="control-label" htmlFor="mName">Middle Name</label>
                              <div className="controls">
                                <input type="text" id="mName" name="mName" placeholder=""
                                value={this.state.mName}
                                onChange={e => {
                                    this.setState({
                                        mName: e.target.value
                                    });
                                }}
                                onKeyDown={e =>{
                                  this.handleChange(e);
                                }}
                                className="input-xlarge"/>
                                <div className="invalid" id="mNameError" ></div>
                              </div>
                            </div>

                            <div className="control-group">
                              <label className="control-label" htmlFor="lName">Last Name</label>
                              <div className="controls">
                                <input type="text" id="lName" name="lName" placeholder=""
                                value={this.state.lName}
                                onChange={e => {
                                    this.setState({
                                        lName: e.target.value
                                    });
                                }}
                                onKeyDown={e =>{
                                  this.handleChange(e);
                                }}
                                className="input-xlarge"/>
                                <div className="invalid" id="lNameError" ></div>
                              </div>
                            </div>

                            <div className="control-group">
                              <label className="control-label"  htmlFor="email">Email</label>
                              <div className="controls">
                                <input type="text" id="email" name="email" placeholder=""
                                value={this.state.email} disabled={true}
                                onChange={e => {
                                    this.setState({
                                        email: e.target.value
                                    });
                                }}
                                className="input-xlarge"/>
                                <div className="invalid" id="emailError" ></div>
                              </div>
                            </div>

                            <div className="control-group">
                                  <label className="control-label"  htmlFor="add1">Address 1</label>
                                  <div className="controls">
                                    <input type="text" id="add1" name="add1" placeholder=""
                                    value={this.state.addrLine1}
                                    onChange={e => {
                                        this.setState({
                                            addrLine1: e.target.value
                                        });
                                    }}
                                    className="input-xlarge"/>
                                    <div className="invalid" id="add1Error" ></div>
                                  </div>
                            </div>

                            <div className="control-group">
                                  <label className="control-label"  htmlFor="add2">Address 2</label>
                                  <div className="controls">
                                    <input type="text" id="add2" name="add2" placeholder=""
                                    value={this.state.addrLine2}
                                    onChange={e => {
                                        this.setState({
                                            addrLine2: e.target.value
                                        });
                                    }}
                                    className="input-xlarge"/>
                                    <div className="invalid" id="add2Error" ></div>
                                  </div>
                            </div>

                            <Location country={this.state.country} stateName={this.state.stateName}
                              city={this.state.city} postal={this.state.customer.postal}
                              profileCallback={this.locationCallback} />

                            <div className="control-groupcustome">
                              <label className="control-label"  htmlFor="password_confirm">Remarks</label>
                              <div className="controls">
                                <textarea className="custometxt"></textarea>
                              </div>
                            </div>

                            <div className="control-group btnwrapper">
                              <div className="controls">
                                <button type="submit" className="btn btn-success" onClick={e => {this.checkUpdateInput(e)}}>Update</button>
                              </div>
                            </div>
                          </fieldset>
                  </form>
            </div>
          </div>
            ) : (<div></div>)
            }
          </div>
          </div>

        );
    }
}

export default MyProfile;

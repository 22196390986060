import React, { PureComponent } from "react";
import "../../../config/import";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import { Button } from "reactstrap";

class MySubscription extends PureComponent {
    constructor(props,context) {
        super(props,context);
        this.state = {
            apiResponse: [],
            planData: '',
            isData: false,
        };
        this.handleSubscription = this.handleSubscription.bind(this);
        this.timeRemaining = this.timeRemaining.bind(this);
    }

    mySubscription = function () {
        const user = JSON.parse(localStorage.getItem('User'));
        if(user === (undefined || null)) {
            this.props.router.push('/');
            return false;
        }
        const authToken = user['authToken'];
        apiHandler
            .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.subscriptions), { Authorization: authToken })
            .then(response => {
            if (response.status == 200) {
                this.setState({planData: response.data.data.subscription,
                                isData: true})
            } else {
                this.props.router.push('/subscription');
            }
            })
            .catch(err => {
            console.log("err", err);
            });
    }
    componentDidMount() {
        this.mySubscription();
    }
    validUpto(validUpto){
        const planValidUpto = new Date(validUpto);
        const timeNow = new Date(Date.now());
        var days = Math.floor((planValidUpto - timeNow) / (1000 * 60 * 60 * 24));
        var hours = Math.floor(((planValidUpto - timeNow) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        let timeRemaining = days + " Days " + hours + " Hours";
        return timeRemaining;
    }
    formatDate(unsubsDate){
        const finalDate = new Date(unsubsDate);
        return finalDate.toDateString();
    }
    handleSubscription(unsubsDate){
        if(unsubsDate == null){
            this.props.router.push('/unsubscribe');
        }
        else {
            this.props.router.push('/subscription');
        }

    }
    timeRemaining() {
        if (this.state.planData.is_unsubscribed == true || this.state.planData.is_active == false) {
            return "0 Days";
        }
        else {
            return this.validUpto(this.state.planData.valid_to);
        }
    }
    render() {
        return (
            <div>
              {
                  this.state.isData == true ?(
                    <div className="sucessfullwrapper newpaddtop">
                        <div className="sucessfuulcontantnew">
                            <div className="paysustxtnw">{this.state.planData.plan.plan_name}</div>
                            <div className="dashboarditem"><p className="patmentdecnw">{this.state.planData.plan.description}</p>
                            <div className="dashmainwrapper">
                                <div className="dashplanwrapper">
                                    <div className="dashitem">
                                        <span className="leftitem_dash"><strong>Title</strong></span>
                                        <span className="rightitem_dash">{this.state.planData.plan.title}</span>
                                    </div>
                                    <div className="dashitem">
                                        <span className="leftitem_dash"><strong>Price</strong></span>
                                        <span className="rightitem_dash">${this.state.planData.plan.price}</span>
                                    </div>
                                    <div className="dashitem">
                                        <span className="leftitem_dash"><strong>Active</strong></span>
                                        <span className="rightitem_dash">{this.state.planData.is_active == true ? 'Active' : 'Inactive'} </span>
                                    </div>
                                    <div className="dashitem">
                                        <span className="leftitem_dash"><strong>Trial</strong></span>
                                        <span className="rightitem_dash">{this.state.planData.is_trial != true ? 'Inactive' : 'Active'}</span>
                                    </div>
                                </div>
                                <div className="dashplanwrapper">
                                    <div className="dashitem">
                                        <span className="leftitem_dash"><strong>Subscribed on</strong></span>
                                        <span className="rightitem_dash">{ this.formatDate(this.state.planData.subscribed_on)}</span>
                                    </div>
                                    <div className="dashitem subs_block">
                                        <span className="leftitem_dash subs_block_title"><strong>Time Remaining</strong></span>
                                        <span className="rightitem_dash">{this.timeRemaining()}</span>
                                        <span className="rightitem_dash subs_timestamp">{"Updated At:" + Date(Date.now())}</span>
                                    </div>

                                </div>
                                <div className="subnewbtn custombtnNew ">
                                        <Button outline color={(this.state.planData.unsubscribed_on !== null || this.state.planData.is_active != true) ? "secondary" : "warning" }
                                                title ={ this.state.planData.unsubscribed_on !== null ? ( 'Unsubscribed on '+this.formatDate(this.state.planData.unsubscribed_on)) : (this.state.planData.is_active != true ? 'Resubscribe' : 'Unsubcribe')}
                                                onClick={e =>{this.handleSubscription(this.state.planData.unsubscribed_on !== null ? this.state.planData.unsubscribed_on : null)}}>{ (this.state.planData.unsubscribed_on !== null || this.state.planData.is_active != true) ? 'Resubscribe' : 'Unsubscribe'}</Button>
                                    </div>
                            </div>
                            </div>
                        </div>
                </div>)
                      : ''
              }

            </div>

        );
    }
}

export default MySubscription;

import React, { PureComponent } from "react";
import "../../../config/import";
import classnames from "classnames";
import loginBg from "../../../assets/images/login-bg.jpg";
import logoLogin from "../../../assets/images/logo.jpg";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import "../../../assets/styles/login.css";
import {
  Form,
  FormGroup
} from "reactstrap";

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      new_pass: "",
      confirm_new_pass: "",
      reset_msg: "",
      isValid: ""
    };
    if (localStorage.getItem("User")) {
      this.props.router.push('/documents');
    }
    else {
      this.verifyUser = this.verifyUser.bind(this);
      document.body.classList.add('hide-leftnav');
      this.verifyUser();
    }
  }

  verifyUser() {
    const paramData = this.props.params;
    const apiData = {
        email: paramData.email,
        verification_code: paramData.token,
    }
    let api_url = apiUrls.baseUrl.development + apiUrls.endPoints.forgetPasswordToken;
    apiHandler
      .postRequest(api_url, {}, apiData)
      .then(response => {
        if(response.status != '200') {
          this.setState({reset_msg: response.data.message, isValid: false});
        } else {
          this.setState({email: response.data.data.user.email, isValid: true});
        }
        console.log("resp", response);
      })
      .catch(err => {
        console.log("err", err);
      });
  }

  checkInput() {
    let newPass = document.getElementById('newPass');
    let confirmNewPass = document.getElementById('confirmNewPass');

    if (newPass.validity.valueMissing) {
      newPass.setCustomValidity("This field can't be blank");
    }
    else {
      newPass.setCustomValidity("");
    }
    if (confirmNewPass.validity.valueMissing) {
      confirmNewPass.setCustomValidity("This field can't be blank");
    }
    else if (newPass.value != confirmNewPass.value) {
      confirmNewPass.setCustomValidity("Password didn't match");
    }
    else {
      confirmNewPass.setCustomValidity("");
    }
    document.querySelector('#newPassError').innerHTML = newPass.validationMessage;
    document.querySelector('#confirmPassError').innerHTML = confirmNewPass.validationMessage;
  }

  resetPassword = function(e) {
    let form = document.getElementsByTagName('form')[0];
    this.checkInput();
    if (form.checkValidity() === false) {
      e.preventDefault();
      return false;
    }
    else {
      const paramData = this.state;
      const apiData = {
        email: this.state.email,
        password: this.state.new_pass
      }
      apiHandler
        .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.updatePassword), {}, apiData)
        .then(response => {
          if (response.status == '200') {
            this.props.router.push({pathname: '/login', state: {response_msg: response.data.message, callFrom: 'reset'}});
          }
          else {
            this.setState({reset_msg: response.data.message});
          }
        })
        .catch(err => {
          console.log("err", err);
        });
        e.preventDefault();
    }
  }

  render() {
    let alert;
    let resetForm = <div className="innerwrapper">
          <div className="recoverywrapper">
              <h2 className="logtxt">Reset Password</h2>
              {alert}
              <p className="lohptxt recloginlohptxt"></p>
              <form>
                <div className="form-group">
                    <input type="password" className="form-control" id="newPass" placeholder="Enter new password" value={this.state.new_pass} required={true}
                    onChange={e => {
                      this.setState({ new_pass: e.target.value });
                    }}/>
                    <div className="invalid" id="newPassError"></div>
                </div>
                <div className="form-group">
                    <input type="password" className="form-control" id="confirmNewPass" placeholder="Confirm new password" value={this.state.confirm_new_pass} required={true}
                    onChange={e => {
                      this.setState({ confirm_new_pass: e.target.value });
                    }}/>
                    <div className="invalid" id="confirmPassError"></div>
                </div>

                <div className="form-group">
                    <button type="submit" className="logBtn" id="resetBtn"
                    onClick={e => {
                      this.resetPassword(e);
                    }}>Reset</button>
                </div>
              </form>
            </div>
          </div>
    let linkStatus = <div className="link-verify">
            <h4>{this.state.reset_msg}</h4>
            <button className="logBtn">
                {this.state.reset_msg === "This link is expired" ? <a href="/forget_password">Send again</a> : <a href="/">Back to home</a>}
            </button>
          </div>
    if (this.state.reset_msg) {
      alert = <span className="alert-danger">{this.state.reset_msg}</span>
    }
    return (
      <React.Fragment>
      <div className="mainloginWrapper">
        <div className="logo"><img src={logoLogin} className="img-responsive" /></div>
        {this.state.isValid === false ? linkStatus : (this.state.isValid === true ? resetForm : null)}
      </div>
      </React.Fragment>
    );
  }
}

export default ResetPassword;

import React, { PureComponent } from 'react';
import '../../../config/import';
import banner1 from '../../../assets/images/bannerObject1.png';
import editHome1 from '../../../assets/images/editHome1.png';
import cloudHome1 from '../../../assets/images/cloudHome1.png';
import clientImg from '../../../assets/images/client.jpg';
import securedHome1 from '../../../assets/images/secureHome1.png';
import bannerHome1 from '../../../assets/images/bannerHome1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

export default class Home extends PureComponent {
  constructor(props) {
    super(props);
    document.body.classList.add('fixed-header');
  }


  render() {
    return (
      <React.Fragment>
             <div className="topBannerWrap">
            <div className="container">
              <div className="cst-row">

                <div className="leftSection">
                  <div className="discountInfo"><span className="color-pink">25% Discount</span> <span>on every first project</span> </div>
                  <div className="headingWrap">
                    <h1>Securely manage & edit your PDF documents online</h1>
                    <p>PatakaPDF makes it easy to securely edit, share, and sign your PDF documents. Get started with a 14 day free trial.</p>
                    <div className="buttonWrap">
                      <a className="btnBlue" href="/register">Try It Free</a>
                      {/* <button className="btnTransparent"><a href='/subscription'>Explore MORE</a></button> */}
                    </div>
                  </div>
                </div>

                <div className="rightSection">
                  <div className="objectWrapper">
                  <img src={bannerHome1} />
                    <div className="dashboardWrapper">
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className='container'>
               <div className="fratureWrapper">
            <div className="freetrailhead">
              <div className="frtxt">Our Features</div>
              <div className="trytxt">Here's What Sets Us Apart</div>
            </div>
            <div className="fratureitem">
              <div className="itemimgcontainer">
                <img src={editHome1} className="img-responsive" />
              </div>
              <div className="itemtitle">
              Powerful Editing Capabilities
                    </div>
              <div className="itemtxt">
              Our suite of powerful editing tools enables you to easily add or remove pages, merge documents, create fillable fields, redact & sanitize data, and much more.
                    </div>
            </div>

            <div className="fratureitem">
              <div className="itemimgcontainer">
                <img src={cloudHome1} className="img-responsive" />
              </div>
              <div className="itemtitle">
              Web-Based Document Management
                    </div>
              <div className="itemtxt">
              No need to download or install software. With PatakaPDF, you can access your documents online from any device. Organize documents into folders, and share files with your team for easy collaboration.
                    </div>
            </div>

            <div className="fratureitem">
              <div className="itemimgcontainer">
                <img src={securedHome1} className="img-responsive" />
              </div>
              <div className="itemtitle">
              eSignature Workflows
                    </div>
              <div className="itemtxt">
              Send and sign from anywhere. Speed up agreements, reduce manual tasks, and automate reminders.
                    </div>
            </div>
          </div>
          <div className="testtimonialwrapper">
            <div className="testxt">CLIENT COMMENTS</div>

            <div className="testimonialcontant owl-carousel owl-theme" id="testimonialSlider">
              <div className="testimonialitem item">
                <div className="testleft">
                  <span className="blockquote"><FontAwesomeIcon icon={faQuoteLeft} /></span>
                  <p className="bloTxt">Impressed with master class support of the team and really look forward for the future. A true passionate team.</p>
                  <div className="testfooetr">
                    <span className="name">Roman UI Oman</span>
                    <span className="deginatio">Co-founder of QatarDiaries</span>
                  </div>
                </div>
                <div className="testright"><img src={clientImg} className="img-responsive founderImg" /></div>

              </div>
            </div>
          </div>
          <div className="bottomfotter">
                <div className="container">
                    <div className="faqwrapper">
                            <div className="freetrailhead">
                                    <div className="frtxt">HAVE QUESTIONS ?</div>
                                    <div className="trytxt">Perfect, We've Got Answers</div>
                            </div>

                            <div className="faqcontant">
                                <h2>How long does the free trial last ? <span className="plusicons"><FontAwesomeIcon icon={faPlus} /></span></h2>
                                <div className="faqcontxt" style={{display: 'none'}}>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>

                            </div>

                            <div className="faqcontant">
                                    <h2>Is data sanitized after redaction ? <span className="plusicons"><FontAwesomeIcon icon={faPlus} /></span></h2>
                                    <div className="faqcontxt cushide" style={{display: 'none'}}>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </div>
                            </div>

                            <div className="faqcontant">
                                    <h2>How much does PatakaPDF cost ? <span className="plusicons"><FontAwesomeIcon icon={faPlus} /></span></h2>
                                    <div className="faqcontxt cushide" style={{display: 'none'}}>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </div>
                            </div>

                            <div className="faqcontant">
                                    <h2>Is there a file storage lomit ? <span className="plusicons"><FontAwesomeIcon icon={faPlus} /></span></h2>
                                    <div className="faqcontxt cushide" style={{display: 'none'}}>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </div>
                            </div>

                            <div className="faqcontant">
                                    <h2>How can I download or share my PDFs ? <span className="plusicons"><FontAwesomeIcon icon={faPlus} /></span></h2>
                                    <div className="faqcontxt cushide" style={{display: 'none'}}>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </div>
                            </div>

                            <div className="faqfree"><span className="trialbtn" style={{width: 'auto'}}>Try It Free</span></div>
                    </div>

                    <div className="lunchwrapper">
                        <div className="leftlunch">
                            <h1 className="launchtxt">Want to Know when we launch ?</h1>
                            <p className="subtxt">Subscribe to our email list.</p>
                        </div>

                        <div className="rightlunch">
                            <div className="subtextbox"><input type="text" className="subscriptionTxt" placeholder="Email address"/></div>
                            <div className="subbtn trialbtn">SUBSCRIBE</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
      </React.Fragment>

    );
  }
}

import { connect } from 'react-redux';
import documentComponent from '../views/documentComponent/documentComponent';
import { incrementCount } from '../../redux/actions/authentication';

const mapStateToProps = (state) => ({
    count : state.authentication.count
});

const mapDispatchToProps = (dispatch) => ({
    incrementCount : () => dispatch(incrementCount())
});

const DocumentComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(documentComponent);

export default DocumentComponent;
import React, { PureComponent,Component } from "react";
import "../../../config/import";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import './card.scss'
import {
    CardElement,
    injectStripe,
    StripeProvider,
    Elements,
  } from 'react-stripe-elements';

const createOptions = () => {
    return {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          fontFamily: 'Open Sans, sans-serif',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#c23d4b',
        },
      }
    }
  };

  class _CardForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            token: null,
            data: '',
            pub_token: '',
            uid: '',
            element: '',
            name: '',
            email: '',
            phoneNumber: '',
            addrLine1: '',
            addrLine2: '',
            city: '',
            customerState: '',
            country: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
      }

    handleChange = ({error}) => {
      if (error) {
        this.setState({errorMessage: error.message});
      }
      else {
        this.setState({errorMessage: ""});
      }
    };

    handleSubmit = (evt) => {
        evt.preventDefault();
        let intentObj = JSON.parse(localStorage.getItem('intentObj'));
        if (this.props.stripe) {
            this.props.stripe._apiKey = intentObj.pub_token;
            if(intentObj.token != undefined && this.state.element != undefined){
                this.props.stripe.handleCardSetup(
                    intentObj.token.token, this.state.element,
                    {
                        payment_method_data: {
                          billing_details: {
                            name: this.state.name,
                            email: this.state.email,
                            phone: this.state.phoneNumber,
                            address: {
                                city: this.state.city,
                                country: this.state.country,
                                line1: this.state.addrLine1,
                                line2: this.state.addrLine2,
                                state: this.state.customerState
                              }
                          },
                        }
                    }
               ).then(response => {
                        if (response.error) {
                           console.log(response.error);
                           return false;
                       }
                       else if (response) {
                        const user = JSON.parse(localStorage.getItem('User'));
                        const authToken = user['authToken'];
                        const data = {
                                        uid: intentObj.token.uid,
                                        payment_method: response.setupIntent.payment_method,
                                        created: response.setupIntent.created,
                                        payment_method_type: response.setupIntent.payment_method_types[0],
                                        status: response.setupIntent.status
                                    }
                        apiHandler
                            .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.setupAttach), { Authorization: authToken }, data)
                            .then(response => {
                                window.location = '/payment/cards';
                                })
                            .catch(err => {
                                console.log("err", err);
                            });

                    }
                   })
                   .catch(err => {
                       console.log("err", err);
                   });
            }

        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };
    handleReady = (element) => {
        this.setState( {element: element})
      }

    render() {
      return (
        <div className="CardDemo customeform" style={{ textAlign: 'center'}}>
          <form onSubmit={this.handleSubmit.bind(this)}>
              Card details
              <CardElement
                onChange={this.handleChange}
                {...createOptions()}
              />
                <FormGroup>
                    <Label for="exampleEmail">Name</Label>
                    <input type="text" id='name' placeholder='Name'
                    onChange={e => {
                        this.setState({
                            name: e.target.value
                        });
                    }}>
                    </input>
                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <input type="text" id='email' placeholder='email'
                                        onChange={e => {
                                            this.setState({
                                                email: e.target.value
                                            });
                                        }}>
                    </input>
                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">Phone Number</Label>
                    <input type="text" id='number' placeholder='phoneNumber'
                    onChange={e => {
                        this.setState({
                            phoneNumber: e.target.value
                        });
                    }}></input>
                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">Address Line1</Label>
                    <input type="text" id='address1' placeholder='Address Line 1'
                    onChange={e => {
                        this.setState({
                            addrLine1: e.target.value
                        });
                    }}></input>
                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">Address Line2</Label>
                    <input type="text" id='address2' placeholder='Address Line 2'
                                        onChange={e => {
                                            this.setState({
                                                addrLine2: e.target.value
                                            });
                                        }}></input>
                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">City</Label>
                    <input type="text" id='city' placeholder='city'
                                        onChange={e => {
                                            this.setState({
                                                city: e.target.value
                                            });
                                        }}></input>
                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">State</Label>
                    <input type="text" id='state' placeholder='State'
                    onChange={e => {
                        this.setState({
                            countryState: e.target.value
                        });
                    }}></input>
                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">Country</Label>
                    <input type="text" id='country' placeholder='country'
                                        onChange={e => {
                                            this.setState({
                                                country: e.target.value
                                            });
                                        }}></input>
                </FormGroup>

                <FormGroup style={{ textAlign: 'center' }}>

            <div className="error" role="alert">
              {this.state.errorMessage}
            </div>

            <button className="customepaybtn"> Pay</button>
            </FormGroup>
          </form>
        </div>
      );
    }
  }

  const CardForm = injectStripe(_CardForm);


class AddNewComponent extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            apiResponse: [],
            paymentMethod: '',
            isData: false,
            apiKey: JSON.parse(localStorage.getItem('intentObj')).pub_token
        };
    }



    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('User'));
        if(!user){
            this.props.router.push('/login');
            return false;
        }
        const authToken = user['authToken'];
        const hostname = window && window.location && window.location.hostname;
        let api_base_url = '';
        if(hostname === 'localhost') {
            api_base_url = 'http://localhost:3000/jerry';
        }
        else if(hostname === "staging.patakapdf.com") {
            api_base_url = 'https://staging.patakapdf.com/jerry';
        }
        else if(hostname === "testing.patakapdf.com") {
            api_base_url = 'https://testing.patakapdf.com/jerry';
        } else {
            api_base_url = 'https://www.patakapdf.com/jerry';
        }

        apiHandler
        .postRequest((api_base_url+ '/payment_methods/add'), { Authorization: authToken })
        .then(response => {
          if (response.status == 201) {
              let setupIntentObj = {
                token: response.data.data.setup_intent,
                pub_token: response.data.data.setup_intent.pub_key,
                uid: response.data.data.setup_intent.uid
              }
              localStorage.setItem('intentObj', JSON.stringify(setupIntentObj));
              this.setState({
                interObject: setupIntentObj
            });
          }
           else if (response.status == 400 || response.data.message == 'Customer not found') {
            this.props.router.push('/createCustomer');
          }

        })
        .catch(err => {
          console.log("err", err);
        });
      }

    render() {
        return (
          <StripeProvider apiKey={this.state.apiKey}>
            <Elements>
              <CardForm handleResult={this.props.handleResult} />
            </Elements>
          </StripeProvider>
        );
      }
}

export default AddNewComponent;

import React, { PureComponent } from 'react';
import '../../../config/import';

export class FreeTrial extends PureComponent {
   
    render() {
        return (
    <div className="container-940 freeTrialWrap maincustomwrapper">
   
      <h2><span>FREE TRIAL</span>Try PatakaPDF Free for 30 Days</h2>
        <div className="uploadDocumentEnterDocumentWrap">
           <div className="tabWrap">
              <div className="tabNav">
                  <ul>
                    <li className="clickme"><a href="javascript:void(0);" data-tag="one" className="activelink"><i className="fas fa-cloud-upload-alt"></i><span className="text">Upload Document</span></a></li>
                    <li className="clickme"><a href="javascript:void(0);" data-tag="two"><i className="fas fa-link"></i><span className="text">Enter URL of Document</span></a></li>
                 </ul>
              </div>           
              <div className="tabContentWrap">
                 <div id="container">
                    <div className="list" id="one">
                       {/* <!-- progress div           --> */}
                        <div className="processingWrap" id="file-upload-filename">
                           <h2>Processing, Please wait a moment...</h2>
                            <div className="progress">
                               <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: 80}}>
                                 <span className="sr-only">70% Complete</span>
                               </div>
                             </div>
                           <div>
                              <ul className="progressStatus">
                                 <li className="">Uploading Document(s) - 100%</li>
                                 <li className="onprogress">Checking Document(s) for Viruses- 100%</li>
                                 <li className="onprogress">Encrypting Data - 36%</li>
                              </ul>
                           </div>
                        </div>
                        {/* <!-- end --> */}

                     <div className="list2 " id="three">
                        <div className="dragDropDocumentWrap">                         
                           <div className="new_freetrailwrapper">
                             <div className="innercontant">
                                 <h2>Drag and Drop Documents Here to Get Started!</h2>
                          <p>Use the button below to upload your documents to PatakaPDF and begin <span className="bold">editing</span>, <span className="bold">signing</span>, and <span className="bold">sharing</span>. PatakaPDF supports PDF, Word, PowerPoint, <span className="bold">JPEG</span>, <span className="bold">PNG</span> and <span className="bold">Text</span> formats. </p>
                           <div className="browseForDocumentBtn">Browse for a Document on Your Computer</div>
                             </div>
                             <input type="file" name="myfile" id="file-upload" />
                           </div>
                       </div>
                     </div>
                     <div className="list2 hide" id="four">
                        <div className="dragDropDocumentWrap">                         
                           <div className="upload-btn-wrapper">
                             <button className="btn">
                                 <h2>Upload Documents from Dropbox</h2>
                          <p>Use the button below to upload your documents to PatakaPDF and begin <span className="bold">editing</span>, <span className="bold">signing</span>, and <span className="bold">sharing</span>. PatakaPDF supports PDF, Word, PowerPoint, <span className="bold">JPEG</span>, <span className="bold">PNG</span> and <span className="bold">Text</span> formats. </p>
                           <div className="browseForDocumentBtn">Connect to Dropbox</div>
                             </button>
                             <input type="file" name="myfile" id="file-upload" />
                           </div>
                       </div>                        
                     </div>
                     <div className="list2 hide" id="five">
                        <div className="dragDropDocumentWrap">                         
                           <div className="upload-btn-wrapper">
                             <button className="btn">
                                 <h2>Upload Documents from Google Drive</h2>
                          <p>Use the button below to upload your documents to PatakaPDF and begin <span className="bold">editing</span>, <span className="bold">signing</span>, and <span className="bold">sharing</span>. PatakaPDF supports PDF, Word, PowerPoint, <span className="bold">JPEG</span>, <span className="bold">PNG</span> and <span className="bold">Text</span> formats. </p>
                           <div className="browseForDocumentBtn">Connect to Google Drive</div>
                             </button>
                             <input type="file" name="myfile2"  id="file-upload2"  />
                           </div>
                       </div>                        
                     </div>                      
                        <ul className="typeOFFileWrap">
                          <li className="clickme2">
                           {/* <a href="javascript:void(0);" data-tag="three" className="activelink">
                        
                          </a> */}
                       </li>
                          <li className="clickme2">
                           {/* <a href="javascript:void(0);" data-tag="four">
                            
                          </a> */}
                       </li>
                          <li className="clickme2">
                           {/* <a href="javascript:void(0);" data-tag="five">
                        
                          </a> */}
                       </li>                          
                        </ul>
                    </div>
                    <div className="list hide urlDocumentWrap" id="two">
                       <h2>Add Documents from the Web</h2>
                       <p>Enter the URL of a document hosted online to add it to PatakaPDF and begin <span className="bold">editing</span>, <span className="bold">signing</span>, and <span className="bold">sharing</span>. PatakaPDF supports PDF, Word, PowerPoint, <span className="bold">JPEG</span>, <span className="bold">PNG</span> and <span className="bold">Text</span> formats. </p>
                       <div className="fillNowanchorWrap"> 
                          <input type="text" placeholder="http://"/>
                          <button>Fill Now!</button>
                       </div>
                    </div>                 
                </div>  
              </div>
           </div>
        </div>         
      </div>
 
        );
    }
}
export default FreeTrial;




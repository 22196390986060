import React, { Component } from "react";
import "../../../../config/import";
import logo from "../../../../assets/images/logo.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginSignup: false,
      isOpen: true,
      isUserLoggedIn: false,
      userLoggedIn: (localStorage.getItem('User') !== undefined) ? JSON.parse(localStorage.getItem('User')) : "",
      userLogin: false
    };
  }

  componentDidMount() {
    this.checkLogin();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let stateData = { prevState };
    if (nextProps.userLogin != stateData.userLogin) {
      let userLogin, userLoggedIn;
      userLogin = nextProps.userLogin;
      userLoggedIn = (localStorage.getItem('User') !== undefined) ? JSON.parse(localStorage.getItem('User')) : "";
      stateData.isUserLoggedIn = userLoggedIn;
      stateData = {
        userLogin,
        userLoggedIn
      }
    };
    return { stateData };
  }

  closeMenu() {
    document.getElementById("navbarNav").style.display = "none";
    document.getElementById("close-menu").style.display = "none";
  }
  showMenu() {
    document.getElementById("navbarNav").style.display = "block";
    document.getElementById("close-menu").style.display = "block";
  }
  checkLogin = () => {
    if (this.state.userLoggedIn) {
      this.setState({
        isUserLoggedIn: true,
        userLogin: true
      });
    }
  }

  toggleLogin = () => {
    this.setState({ showLoginSignup: true });
  };
  logout = () => {
    localStorage.clear("User");
    this.setState({ isUserLoggedIn: false, userLogin: false });
    window.location = '/';
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const userLogin = this.state.isUserLoggedIn;
    return (
      <React.Fragment>
        <header id="header" className="test" style={{ padding: '0px 0px' }}>
          {
            !userLogin ? (
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/">
                  <img src={logo} alt=""></img>
                </a>
                <div className="mobile-display mar-lf-auto" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item ">
                      <a
                        className="nav-link"
                        href="/login"
                        id="loginModelTrigger"
                      >
                        Log In
                  </a>
                    </li>

                    <li className="nav-item ">
                      <a className="nav-link" href="/register">
                        Free Trial
                  </a>
                    </li>

                  </ul>
                </div>
                <div className="mobilemenu">
                  <span className="navmob-icons" onClick={this.showMenu}><i className="fa fa-bars" aria-hidden="true"></i></span>
                  <span id="close-menu" className="close-menu" onClick={this.closeMenu}><i className="fa fa-close"></i></span>
                </div>
              </nav>
            ) : (
                <Navbar color="light" light expand="md" className='custommanubar'>
                  <NavbarToggler onClick={this.toggle} />
                  <a className="navbar-brand" href="/" style={{ 'cursor': 'pointer' }}>
                    <img src={logo} alt=""></img>
                  </a>
                  
                  <Collapse isOpen={this.state.isOpen} className='ml-auto'>
                    <Nav className="ml-auto custommanubar" navbar>
                      <NavItem>
                      </NavItem>
                      <NavItem>
                      </NavItem>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          {this.state.userLoggedIn['name']}
                          <span className="btn-icon"><i className="flaticon-user"></i></span>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem divider />
                          <DropdownItem><a href='/profile'>My Profile</a></DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem><a href='/documents'>Documents</a></DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem><a href='/subscription/status'>Subscription</a></DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            onClick={this.logout}
                          >Log out</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Collapse>
                </Navbar>
              )
          }
        </header>

      </React.Fragment>
    );
  }
}
export default Header;

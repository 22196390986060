import {create} from 'apisauce';
import ApiUrls from '../../constants/apiUrls';

const api = create({
    baseURL: ApiUrls.baseUrl.development,
});

function ApiHandler() {
    // get Request
    this.getRequest = (url, headers = {}) => {
        return api.get(url, {}, {headers: addCommonHeaders(headers)}); //need to look into this
    };

    /* POST METHOD */
    this.postRequest = (url, headers = {}, params) => {
        return api.post(url, params, {headers: addCommonHeaders(headers)});
    };

}

const addCommonHeaders = (additionalHeaders = {}) => {
    let defaultHeaders = {
        'Content-Type': 'application/json',
        'N-Platform': 'web',
        'N-Device': 'web',
        'source': 'web'
    };

    return {...defaultHeaders, ...additionalHeaders};
};



const APIClient = new ApiHandler();

export default APIClient;
import React, { PureComponent } from "react";
import "../../../config/import";
import logoLogin from "../../../assets/images/logo.jpg";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import "../../../assets/styles/login.css";

class PrivacyPolicy extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    document.body.classList.add('hide-leftnav');
  }

  render() {
    let policyComp = <div className="text-center">
        <h3>Privacy Policy</h3>
        <h2>Coming soon!</h2>
    </div>        

    return (
      <React.Fragment>
        <div className="mainloginWrapper">
          <div className="logo"><img src={logoLogin} className="img-responsive" /></div>
          <div>
            {policyComp}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;

import { connect } from 'react-redux';
import AddNewCardComponent from '../views/subscription/AddNewCardComponent';
import { incrementCount } from '../../redux/actions/authentication';

const mapStateToProps = (state) => ({
    count : state.authentication.count
});

const mapDispatchToProps = (dispatch) => ({
    incrementCount : () => dispatch(incrementCount())
});

const AddNewCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewCardComponent);

export default AddNewCard;
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'
import authenticationReducer from './authenticationReducer';

const rootReducer = combineReducers({
    routing: routerReducer,
    authentication: authenticationReducer
});

export default rootReducer

import { connect } from 'react-redux';
import HeaderComponent from '../views/shared/header/headerComponent';

const mapStateToProps = (state) => ({
    userLogin : state.authentication.userLogin
});

const mapDispatchToProps = (dispatch) => ({
});

const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);

export default Header;
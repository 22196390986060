import React, { PureComponent } from "react";
import { withRouter } from 'react-router-dom';
import "../../../config/import";
import "./subscription.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";

class Subscription extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: [],
      planSubscribed: false,
      customerPresent: false,
      planUid: '',
      product_uid: '',
      is_subscribed: false
    };
  }
  componentDidMount() {
    this.fetchSubscription();
    window.scrollTo(0, 0)
  }

  checkCustomer = function (e) {
    const user = JSON.parse(localStorage.getItem('User'));
    const authToken = user['authToken'];
    const planElement = e.currentTarget.parentElement.parentElement.getElementsByClassName('plan_uid')[0].value;
    const currentPlan = JSON.parse(planElement);
    // const product_uid = e.target.parentNode.parentNode.parentNode.children.product_uid.value
    const product_uid = e.currentTarget.getAttribute('value');
    const subscribedPlan = currentPlan.plan_uid;
    const product_data = JSON.stringify({ product_uid: product_uid, plan_uid: subscribedPlan })
    let data = {
      name: user['name'],
      email: user['email']
    }
    apiHandler
      .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.customerFetch), { Authorization: authToken })
      .then(response => {
        if (response.status == 200) {
          this.props.router.push({pathname: '/payment', state: {productData: JSON.parse(product_data), planData: JSON.parse(planElement), email: user.email, is_subscribed: this.state.is_subscribed}});
        } else {
          apiHandler
            .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.customerCreate), { Authorization: authToken }, data)
            .then(response => {
                if (response.status === 201) {
                  this.props.router.push({pathname: '/payment', state: {productData: JSON.parse(product_data), planData: JSON.parse(planElement), email: user.email, is_subscribed: this.state.is_subscribed}});
                } else {
                  alert(response.data.message);
                  return false;
                }
            })
            .catch(err => {
                console.log("err", err);
                return false;
            });
        }

      })
      .catch(err => {
        console.log("err", err);
      });
  }

  fetchSubscription = function (e) {
    // fetching all subscription plans
    const user = JSON.parse(localStorage.getItem('User'));
    if(!user){
      this.props.router.push('/');
      return false;
    }
    const authToken = user['authToken'];
    apiHandler
      .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.subcriptionListing), {})
      .then(response => {
        this.setState({ apiResponse: response.data.data.products });
      })
      .catch(err => {
        console.log("err", err);
      });
    // check if subscribed
    apiHandler
      .getRequest((apiUrls.baseUrl.development + apiUrls.endPoints.subscriptions), { Authorization: authToken })
      .then(response => {
        if (response.data.data.subscription.is_active == true) {
          this.setState({ planSubscribed: true });
          alert('Subscription Already present');
          this.props.router.push('/');
        }
        else if (response.data.data.subscription) {
          this.setState({is_subscribed: true});
        }
      })
      .catch(err => {
        console.log("err", err);
      });
  }
  subcribePlan = function (e) {
    if (!this.state.planSubscribed) {
      this.checkCustomer(e);
    }

  }

  changePlan = function(e){
    this.setState({planUid: e.currentTarget.value});

  }


  render() {
    return (
      <div className="plancontainer">
        <div className="planewconatnt">
        <div className="topbanner">
                    <h2 className="bannertxt">Start your FREE trial of PatakaPDF</h2>
                    <p>Choose the Premium Plan to Get the Fully Integrated SignNow e-signature solution don't Worry, You can always plans or cancel anytime.No matter which plan you choose, a 7 DAY FREE TRIAL is included in each plan.</p>
        </div>
          {
            this.state.apiResponse.map(value =>
              <div className="basicplan plandes">
                <div className="palncontantwrap">
                  <h3 className="planhead">{value.name}</h3>
                  <p className="plndes">{value.description}</p>
                  <ul>
                    <li>
                      <span className="checkicons">
                        <FontAwesomeIcon icon={faCheck} /></span> Edit, Fill, Draw, Save, or Fax
                            </li>

                    <li>
                      <span className="checkicons">
                        <FontAwesomeIcon icon={faCheck} /></span> Convert PDFs to Editable Word Documents
                            </li>
                    <li>
                      <span className="checkicons">
                        <FontAwesomeIcon icon={faCheck} /></span> Erase, Highlight & Re-wtite PDFs
                            </li>
                    <li>
                      <span className="checkicons">
                        <FontAwesomeIcon icon={faCheck} /></span> Access Document from Anywhere
                            </li>
                  </ul>

                  <div className="veiwmore">VIEW MORE</div>
                  <div className="priecontant">
                    <span className="prcamount">${value.plans[0].price}</span>
                    <span className="permnt">per month</span>
                  </div>
                  <form>
                  <div className="chooseplan">
                    <p className="plantaxt">Choose Payment Schedule</p>
                    <select value={this.state.planUid} className='plan_uid'
                    onChange={ e=>{
                      this.changePlan(e)
                    }
                    }
                    >
                    {
                       value.plans.map(plan =>
                       <option value={JSON.stringify(plan)}>{plan.title} (${plan.price_per_month})</option>

                     )
                    }

                    </select>
                  </div>

                  <div className="monbtn"><span className="planbutton" value={value.uid}
                    onClick={e => {
                      this.subcribePlan(e);
                      }}

                  >Subscribe</span></div>
                  </form>
                </div>


              </div>


          )
          }
           <div className="planbtmtxt">All plans include unlimited cloud storage absolutely for free Access your documents from Anywhere with our free App.</div>
      </div>
      </div>

    );
  }
}

export default withRouter(Subscription);

import React, {PureComponent} from 'react';

export default class NotFound extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: 4
    }

  }


  render() {
    return (
      <div className="container">
        <div id="notfound">
          <div class="notfound">
            <div class="notfound-404"></div>
            <h1>404</h1>
            <h2>Oops! Page Not Found</h2>
            <p>Sorry but the page you are looking for does not exist, have been removed or is temporarily unavailable</p>
            <a href="/">Back to homepage</a>
          </div>
        </div>
      </div>
    );
  }
}

import { connect } from 'react-redux';
import createCustomerComponent from '../views/create-customer/createCustomer';
import { incrementCount } from '../../redux/actions/authentication';

const mapStateToProps = (state) => ({
    count : state.authentication.count
});

const mapDispatchToProps = (dispatch) => ({
    incrementCount : () => dispatch(incrementCount())
});

const createCustomer = connect(
  mapStateToProps,
  mapDispatchToProps
)(createCustomerComponent);

export default createCustomer;
const hostname = window && window.location && window.location.hostname;
let apiUrls = {
    baseUrl: {
        development: '',
    },
    endPoints: {
        signup: '/auth/signup',
        login: '/auth/login',
        verifyToken: '/auth/signup/verify-email',
        resendVerificationToken: '/auth/signup/resend-verification-code',
        forgetPassword: '/auth/reset',
        forgetPasswordToken: '/auth/reset/verify-token',
        updatePassword: '/auth/reset/update-password',
        resendPasswordToken: '/auth/reset/resend-token',
        subcriptionListing: '/pub/products',
        customerFetch: '/customers/fetch',
        customerCreate: '/customers',
        setupIntent: '/setup_intent',
        setupAttach: '/setup_intent/attach',
        subscriptions: '/subscriptions',
        getDirectories: '/directory' ,
        createDirectories: '/directories',
        pdfFile: '/documents',
        replacePdf: '/documents/replace',
        uploadStatus: '/documents/upload-status',
        paymentMethod: '/payment_methods',
        addNewCards: '/payment_methods/add',
        fetchDeleted: '/documents/deleted',
        fetchDeletedDirectories: '/directories/deleted',
        unsubscribe: '/subscriptions/unsubscribe',
        updateCustomer: '/customers/update',
        searchDocument: '/documents/search',
        searchTrash: '/documents/search-trash'
    }
};
if(window.location.hostname === 'localhost') {
    apiUrls.baseUrl.development = 'http://localhost:3000/jerry';
} else if(window.location.hostname === "staging.patakapdf.com") {
    apiUrls.baseUrl.development = 'https://staging.patakapdf.com/jerry';
} else if(window.location.hostname === "testing.patakapdf.com") {
    apiUrls.baseUrl.development = 'https://testing.patakapdf.com/jerry';
} else {
    apiUrls.baseUrl.development = 'https://www.patakapdf.com/jerry';
}


export default apiUrls;

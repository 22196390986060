import React, { PureComponent } from "react";
import "../../../config/import";
import { Form, FormGroup, Input } from 'reactstrap';
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import '../subscription/subscription.css';
import Location from "../shared/location/locationComponent";

class CreateCustomer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            apiResponse: [],
            customer_email: '',
            customer_name: '',
            customer_address1: '',
            customer_address2: '',
            customer_city: '',
            customer_state: '',
            customer_country: '',
            customer_zipcode: '',
            selectedPlan: this.props.location.state.planData,
            user: JSON.parse(localStorage.getItem('User'))
        };
        this.createProfile = this.createProfile.bind(this);
        this.profileButton = this.profileButton.bind(this);
        this.checkZipValidation = this.checkZipValidation.bind(this);
        this.formValid = this.formValid.bind(this);
    }
    checkInput(e) {
        if (e.currentTarget.value.trim() == "") {
          e.currentTarget.setCustomValidity("Required Field");
        }
        else {
          e.currentTarget.setCustomValidity("");
        }
        if (e.currentTarget.parentElement.getElementsByClassName("customerError").length != 0) {
          e.currentTarget.parentElement.getElementsByClassName("customerError")[0].innerHTML = e.currentTarget.validationMessage;
        }
    }

    checkZipValidation(zip) {
      let zipCode = document.querySelector('#zip');
      if (zip.trim() == "") {
        zipCode.setCustomValidity("Required Field");
      }
      else {
        zipCode.setCustomValidity("");
      }
      if (zipCode.parentElement.getElementsByClassName("customerError").length != 0) {
        zipCode.parentElement.getElementsByClassName("customerError")[0].innerHTML = zipCode.validationMessage;
      }
      else {
        if (this.state.customer_country == 'US' && (zip.length < 5 || zip.length > 5)) {
          zipCode.setCustomValidity("Invalid Zipcode");
        }
        else if (this.state.customer_country == 'IN' && (zip.length < 6 || zip.length > 6)) {
          zipCode.setCustomValidity("Invalid Zipcode");
        }
        else {
          zipCode.setCustomValidity("");
        }
        document.querySelector("#zipError").innerHTML = zipCode.validationMessage;
      }
      let form = document.getElementsByTagName('form')[0]
      let createButton = document.getElementsByClassName('paynowbtn')[0];
      if (form.checkValidity()) {
        createButton.classList.remove('fadeAway');
      }
      else {
        createButton.classList.add('fadeAway');
      }
    }

    formValid() {
      document.getElementById('country').classList.add('form-control');
      document.getElementById('state').classList.add('form-control');
      document.getElementById('city').classList.add('form-control');
      var zip = document.getElementById('zip');
      zip.classList.add('form-control');
      zip.setAttribute("placeholder","Zip");
      document.getElementsByTagName('form')[0].addEventListener( 'change', function() {
        let createButton = document.getElementsByClassName('paynowbtn')[0];
        let form = document.getElementsByTagName('form')[0];
        if (this.checkValidity()) {
          createButton.classList.remove('fadeAway');
        }
        else {
          createButton.classList.add('fadeAway');
        }
      });
    }

    profileButton = function() {
        document.getElementsByClassName('tncCheck')[0].addEventListener( 'change', function(e) {
        let checkBox = document.getElementById('tnC')
        if(!checkBox.checked) {
          checkBox.setCustomValidity("Required field");
        }
        else {
          checkBox.setCustomValidity("");
        }
        console.log(document.getElementsByTagName('form')[0].checkValidity());
      });
    }

    createProfile = function (e) {
        const tnC = document.getElementsByTagName('form')[0].checkValidity();
        if (!tnC) {
            e.preventDefault();
            return false;
        }
        const currentState = this.state;
        const user = currentState.user
        const authToken = user['authToken'];
        let data = {
            name: currentState.customer_name,
            email: currentState.customer_email,
            address1: currentState.customer_address1,
            address2: currentState.customer_address2,
            city: currentState.customer_city,
            state: currentState.customer_state,
            country: currentState.customer_country,
            postal: currentState.customer_zipcode
        }
        let isCustomerCreated = false;
        // check if subscribed
        apiHandler
            .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.customerCreate), { Authorization: authToken }, data)
            .then(response => {
                if (response.status === 201) {
                    isCustomerCreated = true;
                } else {
                    if (response.data.message === "Customer already exists") {
                        isCustomerCreated = true;
                    } else {
                        alert(response.data.message);
                        return false;
                    }
                }
                if (isCustomerCreated) {
                    this.props.router.push({pathname: '/payment', state: {productData: this.props.location.state.productData, planData: this.state.selectedPlan, email: this.state.customer_email}});
                }

            })
            .catch(err => {
                console.log("err", err);
                return false;
            });



    }
    componentDidMount() {
        if (!this.state.user) {
            this.props.router.push('/');
        }
        if (!this.state.selectedPlan) {
            this.props.router.push('/subscription');
        }
        this.formValid();
    }

    locationCallback = (locationData) => {
      this.setState({customer_city: locationData.city, customer_state: locationData.stateName, customer_country: locationData.country, customer_zipcode: locationData.postal})
      if (locationData.postal != undefined) {
        this.checkZipValidation(locationData.postal);
      }
    }

    render() {
        return (
            <div className="plancontainer">
                <div className="planewconatnt">
                    <div class="paymentinfo">
                        <h1 class="paytxt">Enter your payment information to Start your 7 Day Free Trial</h1>
                        <p class="paydes">Your first charge will be after your 7 DAY FREE TRIAL ends</p>
                    </div>
                    <div class="sumpayinfocontainer">
                        <div class="summary">
                            {this.state.selectedPlan !== null ?
                                (
                                    <div class="sumcontant">
                                        <div class="sumpaytxt smtxt">Summary</div>
                                        <div class="bsmonthly smtxt">{this.state.selectedPlan.plan_name}</div>
                                        <div class="tdchagre smtxt">Today's charge: $0:00</div>
                                        <div class="payaft smtxt">After 7 days ${this.state.selectedPlan.price_per_month} per month</div>
                                        <div class="switchpre smtxt"><a href='/subscription'>Switch to Plan</a></div>
                                    </div>)
                                : ''
                            }
                            <div class="sumtxtdes">
                                <h3 class="freetxt">TRY IT RISK FREE</h3>

                                <p class="freedes">
                                    You can change plans, or cancel anytime during your 7 DAY FREE TRIAL and you won't be charged anything.
                           </p>
                            </div>
                        </div>
                        <div className="paymentinformation">
                            <div class="sumpaytxt">Create Customer Profile</div>
                            <div className="formcontainer">
                                <Form className="formgroup">
                                    <FormGroup >
                                        <div className="frmleft customerFormInput">
                                            <Input type="email" name="email"
                                                id="customerEmail"
                                                required={true}
                                                placeholder="Email"
                                                onChange={e => {
                                                    this.checkInput(e)
                                                    this.setState({
                                                        customer_email: e.target.value
                                                    });
                                                }}
                                            />
                                            <div className="customerError"></div>
                                        </div>
                                        <div className="frmleft customerFormInput">
                                            <Input type="text" name="name"
                                                id="yourname"
                                                required={true}
                                                placeholder="Full Name"
                                                onChange={e => {
                                                    this.checkInput(e)
                                                    this.setState({
                                                        customer_name: e.target.value
                                                    });
                                                }} />
                                            <div className="customerError"></div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="frmleft customerFormInput">
                                            <Input type="text" name="address"
                                                id="customerAddress"
                                                required={true}
                                                placeholder="Address 1"
                                                onChange={e => {
                                                    this.checkInput(e)
                                                    this.setState({
                                                        customer_address1: e.target.value
                                                    });
                                                }} />
                                            <div className="customerError"></div>
                                        </div>
                                        <div className="frmleft customerFormInput">
                                            <Input type="text" name="address2"
                                                id="customerAddress2"
                                                required={true}
                                                placeholder="Address 2"
                                                onChange={e => {
                                                    this.checkInput(e)
                                                    this.setState({
                                                        customer_address2: e.target.value
                                                    });
                                                }} />
                                            <div className="customerError"></div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                      <Location country={this.state.customer_country != "" ? this.state.customer_country : "US"} stateName={this.state.customer_state != "" ? this.state.customer_state : "Alabama"} city={this.state.customer_city != "" ? this.state.customer_city : ""} zip={this.state.customer_zipcode} className="frmLeft customerFormInput" call="createCustomer" profileCallback={this.locationCallback}/>
                                    </FormGroup>
                                    <div class="termcon">
                                        <span className="termcheck">
                                        <input type="checkbox" id='tnC' name='tnC' className='tncCheck' required={true}
                                          onChange={e => {
                                            this.profileButton(e) }
                                          }/>
                                          <div className="customerError"></div>
                                        </span>
                                        <label for='tnC' style={{ cursor: 'pointer' }}> I have read and agree to the <a href="javascript:void(0);">Terms and conditions</a></label>
                                    </div>
                                    <div class="paynowbtn fadeAway" disabled={true}
                                        onClick={e => {
                                            this.createProfile(e);
                                        }}
                                    >Create Profile</div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default CreateCustomer;

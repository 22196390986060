import React, { PureComponent } from "react";
import "../../../config/import";
import logoLogin from "../../../assets/images/logo.jpg";
import apiHandler from "../../../utilities/handlers/apiHandler";
import apiUrls from "../../../constants/apiUrls";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import "../../../assets/styles/login.css";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: "",
      login_email: "",
      login_password: "",
      login_error_message: "",
      response_msg: "",
      isConfirmed: true
    };
    
  }

  componentWillMount() {
    if (localStorage.getItem("User")) {
      this.props.router.push('/documents');
    }
    else {
      this.checkInput = this.checkInput.bind(this);
      this.confirmEmail = this.confirmEmail.bind(this);
      document.body.classList.add('hide-leftnav');
      if (this.props.params.token) { this.confirmEmail();}
      else {this.setState({mode: "login"});}
    }
  }

  componentDidMount() {
    this.setState({
      login_error_message: "",
      signup_error_message: "",
      response_msg: "",
    });
    if (this.props.location.state) {
      this.setState({response_msg: this.props.location.state.response_msg})
    }
  }

  checkInput(e) {
    let target = e.currentTarget
        if (target.id === "logEmail" && target.validity.valueMissing) {
          target.setCustomValidity("Enter email address");
          document.querySelector("#logEmailError").innerHTML = target.validationMessage;
          return false;
        }
        else if(target.id === "logEmail" && !document.getElementById('logEmail').value.includes('@') || !document.getElementById('logEmail').value.includes('.') || e.currentTarget.value.split('@')[0].trim() == '' ){
          target.setCustomValidity("Please enter valid email address ");
          document.querySelector("#logEmailError").innerHTML = target.validationMessage;
          return false;
        }
        else if (target.id === "logEmail" && !target.validity.valueMissing) {
          target.setCustomValidity("");
          document.querySelector("#logEmailError").innerHTML = target.validationMessage;
        }
        else if (target.id === "logPassword" && target.validity.valueMissing) {
          target.setCustomValidity("Enter password");
          document.querySelector("#logPassError").innerHTML = target.validationMessage;
        }
        else if (target.id === "logPassword" && !target.validity.valueMissing) {
          target.setCustomValidity("");
          document.querySelector("#logPassError").innerHTML = target.validationMessage;
        }
    }

  validateCredentials() {
     if (this.state.mode === "login") {
        let email = document.getElementById('logEmail');
        let pass = document.getElementById('logPassword');
        if (email.validity.valueMissing) {
          document.querySelector("#logEmailError").innerHTML = "Enter email address";
          return false;
        }
        else {
          email.setCustomValidity("");
        }
        if(!document.getElementById('logEmail').value.includes('@') || !document.getElementById('logEmail').value.includes('.') || email.value.split('@')[0].trim() == ''){
          email.setCustomValidity("Please enter valid email address ");
          return false;
        }
        else{
          email.setCustomValidity("");
        }
        if (pass.validity.valueMissing) {
          pass.setCustomValidity("Enter password");
        }
        else {
          pass.setCustomValidity("");
        }
        document.querySelector("#logEmailError").innerHTML = email.validationMessage;
        document.querySelector("#logPassError").innerHTML = pass.validationMessage;
    }
  }

  validateInput = function(e) {
    let form = document.getElementsByTagName('form')[0];
    this.validateCredentials();
    if (form.checkValidity() === false) {
      return false;
    } else {
      return true;
    }
  }

  resendVerificationEmail = function(e) {
    const paramData = this.props.params;
    const apiData = {
      email: this.state.login_email
    }
    apiHandler
      .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.resendVerificationToken), {}, apiData)
      .then(response => {
        if(response.status == '200') {
          this.setState({isConfirmed: true, response_msg: response.data.message, mode: "login", login_email:"", login_password:""});
          this.props.router.push({pathname: '/login'});
        }
      })
      .catch(err => {
        console.log("err", err);
      });
      e.preventDefault();
  }

  forgetPassword = function(e) {
    this.props.router.push({pathname: '/forget_password', state:{email: this.state.login_email}});
  }
  
  confirmEmail() {
    const paramData = this.props.params;
    const apiData = {
        email: paramData.email,
        verification_code: paramData.token,
    }
    let api_url = apiUrls.baseUrl.development + apiUrls.endPoints.verifyToken;
    apiHandler
      .postRequest(api_url, {}, apiData)
      .then(response => {
        if(response.status != '200') {
          this.setState({response_msg: response.data.message, isConfirmed: false, mode: "error"});
        } else {
          this.setState({response_msg: response.data.message, isConfirmed: true, mode: "login"})
        }
        console.log("resp", response);
      })
      .catch(err => {
        console.log("err", err);
      });
  }

  resendToken = function(e) {
    const apiData = {
      email: this.state.login_email
    }
    let api_url = apiUrls.baseUrl.development + apiUrls.endPoints.resendPasswordToken;
    apiHandler
      .postRequest(api_url, {}, apiData)
      .then(response => {
        if(response.status == '200') {
          this.setState({response_msg: response.data.message, isConfirmed: true, mode: "login", login_email:"",login_password:""});
        }
      })
      .catch(err => {
        console.log("err", err);
      });
      e.preventDefault();
  }

  login = function(e) {
    if(!this.validateInput(e)) {
      e.preventDefault();
      return false;
    }
    const paramData = this.state;
    const apiData = {
      email: paramData.login_email,
      password: paramData.login_password
    }
    apiHandler
      .postRequest((apiUrls.baseUrl.development + apiUrls.endPoints.login), {}, apiData)
      .then(response => {
        if(response.status != '200') {
          if(response.data.message == 'This Email ID is not registered with our platform'){
            document.querySelector("#logEmailError").innerHTML = response.data.message;
            return false;
          }
          if(response.data.message == 'Invalid password'){
            document.querySelector("#logPassError").innerHTML = response.data.message;
          }
          else {
            this.setState({
              response_msg: response.data.message,
              isConfirmed: false
            });
            
            return false;
          }
        }
          if(response.status == '403') {
            this.setState({modal: false, showVerifyEmail: true,login_error_message: response.data.message});

          }
         else {
          let userData = {
            name: response.data.data.user.complete_name,
            email: response.data.data.user.email,
            authToken: response.data.data.auth_token
          }
          userData = JSON.stringify(userData);
          localStorage.setItem('User', userData);
          window.location = "/documents";
        }
      })
      .catch(err => {
        console.log("err", err);
      });
      e.preventDefault()
  }

  render() {
    let formComp;
    let alert;
    let linkStatus = <div className="link-verify">
      <h4>{this.state.response_msg}</h4>
      <button className="logBtn">
      {this.state.response_msg === ("This link is expired" || "Your account is pending for verification") ? <a onClick={e => this.resendVerificationEmail(e)}>Resend verification email</a> : <a onClick={(e)=>{this.resendToken(e)}}>Resend reset password email</a>}
      </button>
      <a className="link-home" href="/"><u>Back to home</u></a>
    </div> 
    if (this.props.location.state) {
      alert = <span className="alert-success">{this.state.response_msg}</span>
    }
    if (this.state.response_msg) {
      if (this.state.response_msg === "Your account is pending for verification") {
        alert = <div>
        <span className='alert-danger'>{this.state.response_msg}</span>
        <div className="resendlink"><a href="" className="gerbtn clicklink" onClick={e => this.resendVerificationEmail(e)}>Resend Verification Email</a></div>
        </div>
      }
      else if (this.state.response_msg === "You have requested for a reset password") {
        alert = <div>
        <span className='alert-danger'>{this.state.response_msg}</span>
        <div className="resendlink"><a href="/forget_password"className="gerbtn clicklink" >Resend reset password email</a></div>
        </div>
      }
      else {
        alert = <span className="alert-success">{this.state.response_msg}</span>
      }
    }
    if (this.props.params.token) {
      if (this.state.isConfirmed === true) {
        alert = <span className='alert-success'>{this.state.response_msg}</span>
      }
      else {
        alert = <span className='alert-danger'>{this.state.response_msg}</span>
      }
    }

    formComp = <div className="innerwrapper">
          <div className="loginwrapper">
          <h2 className="logtxt">Log in</h2>
          {alert}
          <p className="loginlohptxt lohptxt"></p>
          <form>
            <div className="form-group" id="loginForm">
                <input type="text" className="form-control" id="logEmail" name="logEmail" placeholder="Email Address" onBlur={e => this.checkInput(e)}
                value={this.state.login_email} required={true}
                onChange={e => {
                  this.setState({ login_email: e.target.value })
                }}/>
                <div className="invalid" id="logEmailError"></div>
            </div>
            <div className="form-group" style={{position: 'relative'}}>
                <input type="password" className="form-control" id="logPassword" name="logPassword" placeholder="Password" onBlur={e => this.checkInput(e)}
                value={this.state.login_password} required={true}
                  onChange={e => {
                    this.setState({ login_password: e.target.value })
                  }}/>
                <div className="invalid" id="logPassError"></div>
            </div>
            <div className="form-group">
                <span className="remme checkbox"><label className="pointer"><input type="checkbox" className="mr-1"/>Remember me</label></span>
                <span className="forgerpasss"><a  className="linkbtn clicklink" onClick={e => {this.forgetPassword(e)}}>Forgot password?</a></span>
            </div>
            <div className="form-group text-center">
                <button type="submit" className="logBtn" id="LogInsub"
                onClick={e => {
                  this.login(e);
                }}>LOG IN</button>
            </div>
          </form>
          <div className="regtxt">Don’t have an account? <a href="/register" className="gerbtn clicklink">Register now</a></div>
      </div>
      </div>

    return (
      <React.Fragment>
        <div className="mainloginWrapper">
          <div className="logo"><img src={logoLogin} className="img-responsive" /></div>
          {this.state.isConfirmed === false ? linkStatus : ((this.state.isConfirmed === true && this.state.mode === "login") ? formComp : null)}
        </div>
      </React.Fragment>
    );
  }
}

export default Login;

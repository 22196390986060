import React from 'react';
import {injectStripe} from 'react-stripe-elements';
// import AddressSection from './AddressSection';
import CardSection from './cardSection';
class CheckoutForm extends React.Component {
    handleSubmit = (ev) => {
      // We don't want to let default form submission happen here, which would refresh the page.
      ev.preventDefault();
      this.props.stripe
      .createPaymentMethod('card', {billing_details: {name: 'Jenny Rosen'}})
      .then(({paymentMethod}) => {
        console.log('Received Stripe PaymentMethod:', paymentMethod);
      });
      this.props.stripe.handleCardPayment('{PAYMENT_INTENT_CLIENT_SECRET}', 'data');
      this.props.stripe.handleCardSetup('{PAYMENT_INTENT_CLIENT_SECRET}', 'data');
      this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});
      this.props.stripe.createSource({
        type: 'card',
        owner: {
          name: 'Jenny Rosen',
        },
      });
    };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {/* <AddressSection /> */}
        <CardSection />
        <button>Confirm order</button>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);
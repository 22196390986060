import React, { Component } from 'react';
import '../../../../config/import';
import logo from '../../../../assets/images/logo.png';
import {resetSession,logout, redirectToLogin} from "../../../../assets/js/frontend.js";

class Footer extends Component {
  constructor(props) {
    super(props);
  }
   
    render() {
        return (
            <footer>
            <div className="text-center">
              <div className="popupwrapper uploadAction" id="sessionExpire" style={{ display: 'none' }}>
                <div className="actionbody">
                    <div className="poptitleHead">
                      <span className="poptitletxt" id="sessionBody">Session is going to get expire in <span id="counter"></span> seconds</span>
                    </div>
                    <h5 className="btn_wrapper_session">
                      <button id="sessionContinue" className="session_cnt_btn" onClick={()=>{resetSession()}}>Yes, Keep me signed in</button>
                      <button id="sessionExit" className="session_cnt_btn disable-btn" onClick={()=>{logout()}}>No, Sign me out</button>
                    </h5>
                    <h5 id="sessionLogin" className="btn_wrapper_session session_cnt_btn hide"><button onClick={()=>{redirectToLogin()}} className="session_cnt_btn">Click here to login</button></h5>
                </div>
              </div>
              <img src={logo} alt=""/>
            </div>
          </footer>  
        );
    }
}
export default Footer;





import {  INCREMENT_COUNT, SET_USER_LOGIN, SET_USER_LOGOUT } from '../constants/authenticationConstants';

const initialState = {
  count: 0,
  userLogin: false
};

const authenticationReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case INCREMENT_COUNT:
    return {
      ...state,
      count: ++state.count
    };
    case SET_USER_LOGIN:
    return {
      ...state,
      userLogin: true
    };
    case SET_USER_LOGOUT:
    return {
      ...state,
      userLogin: false
    };
    default:
      return state;
  }
};

export default authenticationReducer;
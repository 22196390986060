import { connect } from 'react-redux';
import subscription from '../views/subscription/MySubscription';
import { incrementCount, setUserLogin } from '../../redux/actions/authentication';

const mapStateToProps = (state) => ({
    count : state.authentication.count
});

const mapDispatchToProps = (dispatch) => ({
    incrementCount : () => dispatch(incrementCount()),
    setUserLogin : () => dispatch(setUserLogin())
});

const MySubscription = connect(
  mapStateToProps,
  mapDispatchToProps
)(subscription);

export default MySubscription;
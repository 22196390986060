import React from 'react';
import {Route} from 'react-router';
import NotFound from '../frontend/views/not_found';
import Home from "../frontend/views/home";
import Trial from "../frontend/views/free_trial/freeTrialComponent";
import Subscription from '../frontend/containers/subscription';
import Checkout from '../frontend/views/shared/checkoutform/checkoutForm';
import OCR from '../frontend/views/ocrComponent/ocrComponent';
import CreateCustomer from '../frontend/containers/createCustomer';
import Payment from '../frontend/containers/stripePayment';
import Document from '../frontend/containers/document';
import mySubscription from '../frontend/containers/mySubscription';
import Login from '../frontend/containers/login';
import Signup from '../frontend/containers/SignUp';
import ForgetPassword from '../frontend/containers/forgetPassword';
import ResetPassword from '../frontend/containers/resetPassword';
import UserProfile from '../frontend/containers/userProfile';
import Cards from '../frontend/containers/payment';
import AddNewCard from '../frontend/containers/addNewCard';
import Unsubscription from '../frontend/containers/Unsubscribe';
import PrivacyPolicy from '../frontend/views/policy/privacyPolicy';

export default (
  <div>
    {/*Tab views related routes*/}
    <Route path="/" component={Home}/>
    <Route path='free-trial' component={Trial}/>
    <Route path='subscription' component={Subscription}/>
    <Route path='unsubscribe' component={Unsubscription}/>
    <Route path='checkout' component={Checkout}/>
    <Route path='ocr' component={OCR}/>
    <Route path='payment' component={Payment} />
    <Route path='documents' component={Document} />
    <Route path='subscription/status' component ={mySubscription} />
    <Route path='login' component={Login} />
    <Route path='register' component={Signup} />
    <Route path="forget_password" component={ForgetPassword} />
    <Route path="profile" component={UserProfile} />
    <Route path='payment/cards' component={Cards} />
    <Route path="payment/add" component={AddNewCard} />
    <Route path="/login/confirmRegistration/email=:email/token=:token" component={Login} />
    <Route path="/reset_password/email=:email/token=:token" component={ResetPassword} />
    <Route path="/trust/privacy/privacy-policy" component={PrivacyPolicy} />
    <Route path="*" component={NotFound} />
  </div>
);

import React, { PureComponent } from "react";
import '../../../../config/import';
const countries = require('countrycitystatejson');

class Location extends PureComponent {
    constructor(props,context) {
        super(props,context);
        this.state = {
            country: "",
            stateName: "",
            city: "",
            postal: "",
            stateList: {},
            cityList: {},
            customer_email:""
        };
    }

    fetchStates = function(countryVal, route=null) {
      let stateList = countries.getStatesByShort(countryVal);
      if (route) {
        this.setState({stateList: stateList, country: countryVal, stateName: ""});
        document.getElementById("state").selectedIndex = "0";
        this.fetchCities(countryVal, stateList[0],'c');
      }
      else {
        this.setState({stateList: stateList, country: countryVal});
        this.fetchCities(countryVal, this.props.stateName);
      }
    }

    fetchCities = function(countryCode, e, route=null) {
      let stateVal = '';
      if (e != undefined) {
        if(undefined != e.currentTarget ){
          stateVal = e.currentTarget.value;
        } else {
          stateVal = e;
        }
      }
      
      if (route) {
        this.setState({cityList: countries.getCities(countryCode, stateVal), city: ""})
        document.getElementById("city").selectedIndex = "0";
      }
      else {
        this.setState({cityList: countries.getCities(countryCode, stateVal)});
      }
    }

    checkValidation(e) {
      if (e.currentTarget.value.trim() == "") {
        e.currentTarget.setCustomValidity("Required Field");
      }
      else {
        if (this.state.country == 'US' && (this.state.postal.length < 4 || this.state.postal.length > 4)) {
          e.currentTarget.setCustomValidity("Invalid Zipcode");
        }
        else if (this.state.country == 'IN' && (this.state.postal.length < 5 || this.state.postal.length > 5)) {
          e.currentTarget.setCustomValidity("Invalid Zipcode");
        }
        else {
          e.currentTarget.setCustomValidity("");
        }
      }
      document.getElementById("zipError").innerHTML = e.currentTarget.validationMessage;
    }

    componentDidMount() {
      this.fetchStates(this.props.country);
      this.setState({country: this.props.country, stateName: this.props.stateName, city: this.props.city, postal: this.props.postal})
    }

    render() {
        let locationData = {}
        return (
          <span>
            <div className="control-group">
              { this.props.call == null ? (<label className="control-label" htmlFor="country">Country</label>) : null}
                  <div className="controls">
                    <select className="input-xlarge" id="country"
                    onChange={e => {this.fetchStates(e.currentTarget.value,'s');
                      this.setState({
                          country: e.target.value
                      });
                      locationData = {city: this.state.city, stateName: this.state.stateName, country: e.target.value,
                      postal: this.state.postal}
                      this.props.profileCallback(locationData)
                    }}>
                    {(this.props.country != undefined ? this.props.country.toLowerCase() == 'in' : false) ? (<option value='IN' key='0' selected>India</option>) : (<option value='IN' key='0'>India</option>) }
                    {(this.props.country != undefined ? this.props.country.toLowerCase() == 'us' : false) ?(<option value='US' key='1' selected>US</option>) : (<option value='US' key='1'>US</option>) }
                    </select>
                    <div className="invalid" id="countryError" ></div>
                  </div>
            </div>

            <div className="control-group">
              { this.props.call == null ? (<label className="control-label" htmlFor="state">State</label>) : null}
                  <div className="controls">
                    <select className="input-xlarge" id="state"
                    onChange={e => {this.fetchCities(this.state.country,e,'s');
                    this.setState({
                        stateName: e.target.value
                    });
                    locationData = {city: this.state.city, stateName: e.target.value, country: this.state.country,
                    postal: this.state.postal}
                    this.props.profileCallback(locationData)
                  }}>
                      {
                          Object.values(this.state.stateList)
                          .map(value =>
                            this.state.stateName == value ? <option  value={value} selected>{value}</option> : <option value={value}>{value}</option>
                          )
                      }
                    </select>
                    <div className="invalid" id="stateError" ></div>
                  </div>
            </div>

            <div className="control-group">
              { this.props.call == null ? (<label className="control-label" htmlFor="city">City</label>) : null}
                  <div className="controls">
                    <select className="input-xlarge" id="city"
                    onChange={e => {
                      this.setState({
                          city: e.target.value
                      });
                      locationData = {city: e.target.value, stateName: this.state.stateName, country: this.state.country,
                      postal: this.state.postal}
                      this.props.profileCallback(locationData)
                    }}
                    >
                    {
                        Object.values(this.state.cityList)
                        .map(value =>
                          this.state.city == value ? <option value={value} selected>{value}</option> : <option>{value}</option>
                        )
                    }
                    </select>
                    <div className="invalid" id="cityError" ></div>
                  </div>
            </div>

            <div className="control-group">
              { this.props.call == null ? (<label className="control-label" htmlFor="zip">Zip</label>) : null}
              <div className="controls">
                <input type="text" id="zip" name="zip" className="input-xlarge" required={true} value={this.state.postal}
                onChange={e => {
                    this.setState({
                        postal: e.target.value
                    });
                    locationData = {city: this.state.city, stateName: this.state.stateName, country: this.state.country,
                    postal: e.target.value}
                    this.props.profileCallback(locationData)
                }}/>
                <div className="invalid" id="zipError"></div>
              </div>
            </div>
          </span>
        );
    }
}

export default Location;
